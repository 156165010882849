import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../admin-actions/pools/poolsSlice";
import { OPTIONS_PERSISTENT_VALUES } from "../../common/constants";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import { getTenantId } from "../../common/utils";
import AssignmentUsersForm from "./AssignmentUsersForm";
import { RadioGroupsBox } from "./RadioGroups";

const formType = {
  targetUsers: "targetUsers",
  persist: "persistent",
};

const optionsPersistent = [
  {
    value: OPTIONS_PERSISTENT_VALUES.manual,
    title: `Persistent - ${OPTIONS_PERSISTENT_VALUES.manual}`,
    description:
      "Admins manually assign users to specific resources within the pool.",
    isChecked: false,
  },
  {
    value: OPTIONS_PERSISTENT_VALUES.automated,
    title: `Persistent - ${OPTIONS_PERSISTENT_VALUES.automated}`,
    description:
      "Users are automatically assigned to resources within the pool.",
    isChecked: false,
  },
];

export const PersistentForm = ({
  title,
  description,
  onChangeTargetUsers,
  onChangePersist,
  initialValues = { persistent: "", targetUsers: [] },
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loadingUsers, users } = useSelector(
    (state) => state.adminActions.pools,
  );
  const [formValues, setFormValues] = useState({
    persistent: initialValues.persistent,
    targetUsers: initialValues.targetUsers,
  });

  const { organizationId } = useOrgAndTenantId();
  const { tenantId: tenantIdOnboarding } = useSelector(
    (state) => state.onboarding,
  );

  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const tenantId = getTenantId(selectedPartner);
  const currentTenant = tenantId || tenantIdOnboarding;
  useEffect(() => {
    if (currentTenant && organizationId) {
      dispatch(
        getUsers({ tenantId: currentTenant, organizationId, useCache: true }),
      );
    }
  }, [currentTenant, organizationId, dispatch]);

  const internalChange = (value, form) => {
    setFormValues((oldForm) => ({ ...oldForm, [form]: value }));
    if (form === formType.persist) {
      onChangePersist(value);
    } else {
      onChangeTargetUsers(value);
    }
  };

  const usersOptions = users
    ?.filter(
      (i) => i?.userPrincipalName && !i.userPrincipalName.includes("#EXT#"),
    )
    .map((i) => ({
      value: i?.userPrincipalName,
      label: i?.displayName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {(title || description) && (
        <Grid item xs={12} color={theme.palette.foreground.light}>
          {title && (
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              {title}
            </Typography>
          )}
          {description && <p>{description}</p>}
        </Grid>
      )}
      <Grid item xs={12}>
        <RadioGroupsBox
          defaultValue={initialValues.persistent}
          options={optionsPersistent}
          onChange={(e) => {
            internalChange(
              e.find((item) => item.isChecked === true).value,
              formType.persist,
            );
          }}
        />
      </Grid>
      {formValues.persistent === OPTIONS_PERSISTENT_VALUES.automated && (
        <>
          {/* //TODO: Waiting for the backend to be ready in order to add the Entra group.  */}
          {/* <Grid item xs={12}>
                            <SelectGroupsPagination
                              onChange={(e) => {
                                handleChange(
                                  "entraGroups",
                                  e.map((option) => option.name),
                                  form,
                                );
                              }}
                              ref={groupFormRef}
                              securityEnabled={true}
                              onFormValidityChange={() => {}}
                              label={"Entra groups (optional)"}
                              formName={"Entra groups"}
                              setFormValues={() => {}}
                            />
                          </Grid> */}
          <Grid item xs={12}>
            <AssignmentUsersForm
              initialValues={{
                assignmentUser: initialValues.targetUsers,
              }}
              onChange={(value) => {
                internalChange(value, formType.targetUsers);
              }}
              options={usersOptions}
              loading={loadingUsers}
              label="Target users (optional)"
            />
          </Grid>
        </>
      )}
    </Box>
  );
};
