import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { logger } from "redux-logger";
import adminActionReducer from "./admin-actions/adminActionsSlice";
import azureB2CReducer from "./azureB2C/azureB2CSlice";
import authReducer from "./common/authSlice";
import ciSliceReducer from "./common/ciSlice";
import identitiesReducer from "./common/identitiesSlice";
import notificationReducer from "./common/notificationSlice";
import partnerReducer from "./common/partnersSlice";
import permissionsReducer from "./common/permissionsSlice";
import rolesReducer from "./common/rolesSlice";
import sidebarReducer from "./common/sidebarSlice";
import ctaReducer from "./common/slices/ctaSlice";
import userPreferencesReducer from "./common/slices/userPreferencesSlice";
import { isDevelopment } from "./common/utils";
import dashboardReducer from "./dashboard/slices";
import notificationMiddleware from "./middleware/notificationMiddleware";
import azureRegionReducer from "./onboarding/azureRegionsSlice";
import onboardingReducer from "./onboarding/onboardingSlice";
import { orderSummaryApi } from "./order-summary/orderSummarySlice";
import countriesReducer from "./registration/countriesSlice";
import organizationIdentityReducer from "./registration/organizationIdentitySlice";
import organizationReducer from "./registration/organizationsSlice";
import statesReducer from "./registration/statesSlice";

const store = configureStore({
  reducer: {
    azureB2C: azureB2CReducer,
    organizations: organizationReducer,
    identities: identitiesReducer,
    organizationIdentity: organizationIdentityReducer,
    countries: countriesReducer,
    states: statesReducer,
    onboarding: onboardingReducer,
    azureRegions: azureRegionReducer,
    partners: partnerReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    adminActions: adminActionReducer,
    ci: ciSliceReducer,
    orgRoles: rolesReducer,
    userPreferences: userPreferencesReducer,
    permissions: permissionsReducer,
    sidebar: sidebarReducer,
    cta: ctaReducer,
    notifications: notificationReducer,
    [orderSummaryApi.reducerPath]: orderSummaryApi.reducer,
  },
  devTools: isDevelopment(),
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();

    if (isDevelopment()) {
      middleware = middleware.concat(logger);
    }

    middleware = middleware.concat(orderSummaryApi.middleware);

    // Integrate the notification middleware
    middleware = middleware.concat(notificationMiddleware);

    return middleware;
  },
});

setupListeners(store.dispatch);

export default store;
