import { styled } from "@mui/material";
import { RadioButton, RadioGroup } from "@veneer/core";
import { useState } from "react";

const Container = styled("div")(({ theme, checked }) => ({
  width: "100%",
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  borderRadius: "12px",
  border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.background.stroke}`,
  "& .containerText": {
    display: "flex",
    flexDirection: "column",
  },
  "& h6": {
    fontSize: "16px",
    margin: 0,
  },
  "& span": {
    fontSize: "12px",
  },
}));

const RadioBox = ({ value, title, description, isChecked, onClick }) => (
  <Container checked={isChecked}>
    <RadioButton
      id={value}
      value={value}
      onClick={() => onClick(value)}
      checked={isChecked}
    />
    <div className="containerText">
      <h6>{title}</h6>
      <span>{description}</span>
    </div>
  </Container>
);

export const RadioGroupsBox = ({
  options = [],
  onChange,
  alignment = "vertical",
  style = {},
  defaultValue,
}) => {
  const defaultOptionsValues = options.map((option) => {
    if (option.value === defaultValue) {
      return {
        ...option,
        isChecked: true,
      };
    }
    return {
      ...option,
      isChecked: false,
    };
  });
  const [optionsValue, setOptionsValue] = useState(defaultOptionsValues);
  const handleOnClick = (value) => {
    const newValues = optionsValue.map((i) => {
      if (i.value === value) {
        return { ...i, isChecked: true };
      }
      return { ...i, isChecked: false };
    });
    setOptionsValue(newValues);
    onChange(newValues);
  };
  return (
    <RadioGroup alignment={alignment} style={{ width: "100%", ...style }}>
      {optionsValue.map((option) => {
        return (
          <RadioBox key={option.value} {...option} onClick={handleOnClick} />
        );
      })}
    </RadioGroup>
  );
};
