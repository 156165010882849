import { Grid, styled } from "@mui/material";
import { IconPencil, Card as VeneerCard } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import CopyInfo from "./CopyInfo";
import NoDataDisplayCard from "./NoDataDisplayCard";
import VeneerButton from "./VeneerButton";

const PREFIX = "CardDetails";

const baseLabelStyles = (theme) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontFamily: "Forma DJR UI",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  color: theme.palette.foreground.light,
});

const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  divider: `${PREFIX}-divider`,
  details: `${PREFIX}-details`,
  detail: `${PREFIX}-detail`,
  label: `${PREFIX}-label`,
  labelKey: `${PREFIX}-labelKey`,
  labelValue: `${PREFIX}-labelValue`,
  titleContainer: `${PREFIX}-titleContainer`,
};

const CardDetailsRoot = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100px",
  overflowX: "auto",
  overflowY: "auto",

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "16px 24px ",
  },

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },

  [`& .${classes.title}`]: {
    ...baseLabelStyles(theme),
    fontSize: "20px",
    fontWeight: 700,
    color: theme.palette.foreground.default,
  },

  [`& .${classes.divider}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.stroke,
    height: "1px",
    margin: "8px 0",
  },

  [`& .${classes.details}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },

  [`& .${classes.detail}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.label}`]: {
    ...baseLabelStyles(theme),
  },

  [`& .${classes.labelKey}`]: {
    ...baseLabelStyles(theme),
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },

  [`& .${classes.labelValue}`]: {
    ...baseLabelStyles(theme),
    color: theme.palette.foreground.default,
    textAlign: "right",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },

  "& a": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
      textDecoration: "none",
    },
  },
}));

const StyledCard = styled(VeneerCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.background.stroke}`,
}));

const CardDetails = ({
  title,
  details,
  isCopiable = false,
  isEditable = false,
  onAddClick = () => {},
  numColumns = 1,
}) => {
  const renderDetails = () => {
    // Filter out details without values
    const filteredDetails = details.filter((detail) => detail.value);

    // Create an array of empty arrays, one for each column
    const columns = Array.from({ length: numColumns }, () => []);

    // Calculate the total number of items that have a value
    const totalItems = filteredDetails.length;

    // Calculate the number of items per column, ensuring the left column has one extra item if odd
    const itemsPerColumn = Math.ceil(totalItems / numColumns);

    // Distribute the items into columns
    filteredDetails.forEach((detail, index) => {
      // Determine which column the current item should go into
      const columnIndex = Math.floor(index / itemsPerColumn);
      columns[columnIndex].push(detail);
    });

    // Determine if a divider should be rendered between two items
    // The divider should not be rendered if the current item is the last item in the column
    // or if the number of items in the previous column is greater than the number of items in the current column
    const shouldRenderDivider = (colIndex, index) => {
      if (colIndex === 0) {
        return index + 1 !== columns[colIndex].length;
      }
      return columns[colIndex - 1].length > columns[colIndex].length;
    };

    return (
      <Grid container spacing={2}>
        {columns.map((column, colIndex) => (
          // divide the grid into equal columns based on the number of columns
          <Grid item xs={12 / numColumns} key={colIndex}>
            {column.map((detail, index) => (
              <React.Fragment key={index}>
                <Grid container className={classes.details}>
                  <Grid item xs={4}>
                    <p className={`${classes.label} ${classes.labelKey}`}>
                      {detail.label}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={`${classes.label} ${classes.labelValue}`}>
                      {detail.value}
                    </p>
                  </Grid>
                  {isCopiable && (
                    <Grid
                      item
                      xs={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <CopyInfo
                        dataTestId={detail.label}
                        message={`${detail.label} copied to the clipboard`}
                        customValue={detail.value}
                      />
                    </Grid>
                  )}
                </Grid>
                {shouldRenderDivider(colIndex, index) && (
                  <div className={classes.divider} />
                )}
              </React.Fragment>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <CardDetailsRoot>
      {title && (
        <div className={classes.titleContainer}>
          <p className={classes.title}>{title}</p>
          {isEditable && (
            <VeneerButton
              appearance="secondary"
              leadingIcon={<IconPencil />}
              small
              style={{ border: "none", marginLeft: "8px" }}
              onClick={onAddClick}
            >
              Edit
            </VeneerButton>
          )}
        </div>
      )}
      <StyledCard
        background="filled"
        border="outlined"
        content={
          details?.length > 0 ? (
            <div className={classes.content}>
              <Grid container>{renderDetails()}</Grid>
            </div>
          ) : (
            <NoDataDisplayCard title={title} onAddClick={onAddClick} />
          )
        }
      />
    </CardDetailsRoot>
  );
};

CardDetails.propTypes = {
  title: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node,
    }),
  ).isRequired,
  isCopiable: PropTypes.bool,
  isEditable: PropTypes.bool,
  onAddClick: PropTypes.func,
  numColumns: PropTypes.number,
};

export default CardDetails;
