import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VerticalStepper from "../../../../common/components/VerticalStepper";
import useCurrentTenant from "../../../../common/hooks/useCurrentTenant";
import { resetAddPoolRegisteredDevices } from "../../poolsSlice";
import {
  useCurrentStep,
  useRegisteredDevicesGetStepContent,
  useRegisteredDevicesGetStepsMetaData,
} from "./addPoolRegisteredDevicesFormSettings";

export default function AddPoolRegisteredDevices() {
  const currentTenant = useCurrentTenant();
  const selectedSiteLabel = useSelector(
    (state) => state.adminActions.pools?.selectedSite?.label,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { stepErrors, stepsMetadata } = useRegisteredDevicesGetStepsMetaData();
  const currentStep = useCurrentStep();

  useEffect(() => {
    if (!currentTenant?.id || !selectedSiteLabel) {
      history.push("/dashboard/pools");
    }
  }, [currentTenant?.id, history, selectedSiteLabel]);

  useEffect(() => {
    return () => dispatch(resetAddPoolRegisteredDevices());
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <VerticalStepper
          stepsMetadata={stepsMetadata}
          getStepContent={useRegisteredDevicesGetStepContent}
          showReset={false}
          lastSessionActiveStep={currentStep}
          confirmOnExit={false}
          defaultCancelPath="/dashboard/pools"
          stepErrors={stepErrors}
        />
      </Grid>
    </Grid>
  );
}
