import { addNotification } from "../common/notificationSlice";

const TIMEOUT = 6;

const MACHINE_PROVIDER_SUBJECT = "ServerStatusUpdated";
const RESOURCE_POOL_SUBJECT = "ResourcePoolStatusUpdated";
const SITE_SUBJECT = "SiteStatusUpdated";

const STATUS_MESSAGES = {
  // General status messages
  Deleted: { text: (name) => `${name} has been deleted.`, type: "positive" },
  Deleting: {
    text: (entityType) => `Delete ${entityType} request submitted.`,
    type: "informative",
  },
  Available: { text: (name) => `${name} has been created.`, type: "positive" },
  Creating: {
    text: (entityType) => `Create ${entityType} request submitted.`,
    type: "informative",
  },
  // Machines status messages
  MachineStarted: { text: (name) => `${name} has started.`, type: "positive" },
  MachineStarting: {
    text: (name) => `${name} is starting.`,
    type: "informative",
  },
  MachineStopped: { text: (name) => `${name} has stopped.`, type: "positive" },
  MachineStopping: {
    text: (name) => `${name} is stopping.`,
    type: "informative",
  },
  MachineRestarting: {
    text: (name) => `${name} is restarting.`,
    type: "positive",
  },
  CreateFailed: {
    text: (name) => `Failed to create ${name}.`,
    type: "negative",
  },
  // Pools status messages
  DeletingServers: {
    text: (entityType) => `Delete ${entityType} request submitted.`,
    type: "informative",
  },
  ServerCreationFailed: {
    text: (name) => `Failed to create ${name}.`,
    type: "negative",
  },
};

const MACHINE_STATUS_OVERRIDES = {
  Available: "MachineStarted",
  Starting: "MachineStarting",
  Stopping: "MachineStopping",
  Stopped: "MachineStopped",
  Restarting: "MachineRestarting",
};

const dispatchNotification = (store, name, status) => {
  // if status is undefined or not in STATUS_MESSAGES, we don't want to show a notification
  if (!status || !STATUS_MESSAGES[status]) {
    return;
  }

  const { text, type } = STATUS_MESSAGES[status];

  store.dispatch(
    addNotification({
      text: typeof text === "function" ? text(name) : text,
      type,
      timeout: TIMEOUT,
      id: Date.now(),
    }),
  );
};

const notificationMiddleware = (store) => (next) => (action) => {
  // Check if the action has a payload and a Subject
  const { Subject, Data } =
    action.payload && action.payload.data ? action.payload.data : {};

  switch (Subject) {
    case MACHINE_PROVIDER_SUBJECT: {
      const { ServerName, ServerStatus, ServerOnline } = Data;
      const getMachineStatus = (ServerOnline) => {
        if (ServerOnline === undefined) {
          return undefined;
        }
        return ServerOnline
          ? MACHINE_STATUS_OVERRIDES["Available"]
          : MACHINE_STATUS_OVERRIDES["Stopped"];
      };
      const status =
        getMachineStatus(ServerOnline) ||
        MACHINE_STATUS_OVERRIDES[ServerStatus] ||
        ServerStatus;

      dispatchNotification(store, ServerName, status);
      break;
    }
    case RESOURCE_POOL_SUBJECT: {
      const { ResourcePoolName, ResourcePoolStatus } = Data;
      dispatchNotification(store, ResourcePoolName, ResourcePoolStatus);
      break;
    }
    case SITE_SUBJECT: {
      const { SiteName, SiteStatus } = Data;
      dispatchNotification(store, SiteName, SiteStatus);
      break;
    }
    default:
      break;
  }

  return next(action);
};

export default notificationMiddleware;
