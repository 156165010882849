import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ALL_CUSTOMERS, HTTP_METHODS, TENANT_ID_PREFIX } from "./constants";
import HTTPClient from "./servicers/httpClient";
import {
  getIdentityId,
  getSelectedTenantId,
  getTenantId,
  saveSelectedTenantId,
} from "./utils";
import { isStatusPending, shouldFulfill, shouldRequest } from "./utils/slices";

export const fetchPartners = createAsyncThunk(
  "partners/fetchPartners",
  async (
    { useCache = true, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { fetchRequestId, fetchStatus, tenants = {} } = getState().partners;
    const { userSupportView } = getState().auth;
    const { identities } = getState().identities;

    if (!shouldRequest(fetchStatus, fetchRequestId, requestId)) {
      return;
    }

    if (useCache && tenants && tenants[selectedOrganization]) {
      return { data: tenants[selectedOrganization], selectedOrganization };
    }
    if (userSupportView && identities) {
      return {
        data: identities.find(
          (i) => getIdentityId(i.id) === selectedOrganization,
        )?.tenants,
        selectedOrganization,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const tenantsByOrg = createAsyncThunk(
  "partners/tenantsByOrg",
  async (
    { organizationIds, useCache = true },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      loading,
      tenantsByOrg = {},
    } = getState().partners;
    if (!loading || requestId !== currentRequestId) {
      return;
    }

    const tenantsToFetch = organizationIds.filter(
      (orgId) => !useCache || !tenantsByOrg[orgId],
    );

    if (tenantsToFetch.length === 0) {
      return { tenants: tenantsByOrg, organizationIds };
    }

    try {
      const promises = tenantsToFetch.map((orgId) =>
        new HTTPClient({
          endpoint: `/organizations/${orgId}/tenants`,
          method: HTTP_METHODS.GET,
        }).callAuthorizedAPI(),
      );

      const responses = await Promise.all(promises);

      const tenants = responses.reduce((acc, response, idx) => {
        acc[tenantsToFetch[idx]] = response.data;
        return acc;
      }, {});

      return { tenants, organizationIds };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const stopProcess = createAsyncThunk(
  "partners/stopProcess",
  async (
    { organizationId, tenantId, virtualMachineName, processId, userName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, stoppingProcess } = getState().partners;
    if (!stoppingProcess || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/users/${userName}/processes/${virtualMachineName}/${processId}/stop`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePartner = createAsyncThunk(
  "partners/deletePartner",
  async (
    { selectedOrganization, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deleteTenantRequestId, deletingTenant } = getState().partners;
    if (!deletingTenant || requestId !== deleteTenantRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${selectedTenant}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrganization, selectedTenant };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const savePartner = createAsyncThunk(
  "partners/savePartner",
  async (
    { data, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, saving } = getState().partners;
    if (!saving || requestId !== currentRequestId) {
      return;
    }
    const tenantID =
      data === ALL_CUSTOMERS ? null : data.replace(TENANT_ID_PREFIX, "");
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/current-tenant`,
        method: HTTP_METHODS.POST,
        data: {
          selectedTenant: tenantID,
        },
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  selectedPartner: "",
  requesting: false,
  loading: true,
  tenants: {},
  tenantsByOrg: {},
  deleteTenantRequestId: null,
  deletingTenant: null,
  deletedTenant: null,
  errorWhileDeleting: null,
  deleteStatus: null,
  stoppingProcess: false,
  stoppingProcessError: null,
  saving: false,
  fetchStatus: "idle",
  fetchRequestId: null,
};

export const partners = createSlice({
  name: "partners",
  initialState,
  reducers: {
    setPartner: (state, action) => {
      const tenantId =
        action.payload === ALL_CUSTOMERS
          ? ALL_CUSTOMERS
          : action.payload.startsWith(TENANT_ID_PREFIX)
            ? action.payload
            : TENANT_ID_PREFIX + action.payload;
      if (tenantId !== getSelectedTenantId()) {
        saveSelectedTenantId(tenantId);
      }
      if (tenantId !== state.selectedPartner) {
        state.selectedPartner = tenantId;
      }
    },
    resetActionStatuses: (state) => {
      state.deleteStatus = null;
      state.deletedTenant = null;
    },
    resetStopProcessErrors: (state) => {
      state.stoppingProcess = false;
      state.stoppingProcessError = null;
    },
    updateTenantInPartnerList: (state, action) => {
      if (
        action.payload.selectedOrganization &&
        state.tenants[action.payload.selectedOrganization] &&
        action.payload.tenant
      ) {
        state.tenants[action.payload.selectedOrganization] = state.tenants[
          action.payload.selectedOrganization
        ].map((t) => {
          if (t.id === action.payload.tenant.id) {
            return action.payload.tenant;
          }
          return t;
        });
      }
    },
  },
  extraReducers: {
    [fetchPartners.pending]: (state, action) => {
      if (isStatusPending(state, "fetchStatus")) {
        return;
      }

      state.fetchStatus = "pending";
      state.loading = true;
      state.fetchRequestId = action.meta.requestId;
    },
    [fetchPartners.fulfilled]: (state, action) => {
      const { requestId } = action.meta;

      if (!shouldFulfill(state, "fetchStatus", "fetchRequestId", requestId)) {
        return;
      }

      state.fetchStatus = "idle";
      state.loading = false;
      state.fetchRequestId = null;
      if (action.payload && action.payload.selectedOrganization) {
        state.tenants[action.payload.selectedOrganization] = action.payload
          ? action.payload.data
            ? [].concat(action.payload.data).sort((tenant1, tenant2) => {
                const displayName1 = tenant1?.displayName.toUpperCase();
                const displayName2 = tenant2?.displayName.toUpperCase();
                return displayName1 === displayName2
                  ? 0
                  : displayName1 > displayName2
                    ? 1
                    : -1;
              })
            : []
          : [];
      }
    },
    [fetchPartners.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.fetchRequestId = null;
      state.fetchStatus = "idle";
    },
    [tenantsByOrg.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [tenantsByOrg.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      if (action.payload.organizationIds) {
        action.payload.organizationIds.forEach((orgId) => {
          state.tenantsByOrg[orgId] = action.payload.tenants[orgId] || [];
        });
      }
    },
    [tenantsByOrg.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
    [stopProcess.pending]: (state, action) => {
      state.stoppingProcess = true;
      state.currentRequestId = action.meta.requestId;
      state.stoppingProcessError = null;
    },
    [stopProcess.fulfilled]: (state) => {
      state.stoppingProcess = false;
      state.currentRequestId = null;
    },
    [stopProcess.rejected]: (state, action) => {
      state.stoppingProcessError = action.payload;
      state.stoppingProcess = false;
      state.currentRequestId = null;
    },
    [savePartner.pending]: (state, action) => {
      state.saving = true;
      state.currentRequestId = action.meta.requestId;
    },
    [savePartner.fulfilled]: (state, action) => {
      state.saving = false;
      state.currentRequestId = null;
      state.selectedPartner = action.payload?.selectedTenant
        ? TENANT_ID_PREFIX + action.payload.selectedTenant
        : ALL_CUSTOMERS;
    },
    [savePartner.rejected]: (state, action) => {
      state.error = action.payload;
      state.saving = false;
      state.currentRequestId = null;
      state.selectedPartner = ALL_CUSTOMERS;
    },
    [deletePartner.pending]: (state, action) => {
      state.deletingTenant = true;
      state.deleteTenantRequestId = action.meta.requestId;
      state.deletedTenant = null;
      state.deleteStatus = null;
    },
    [deletePartner.fulfilled]: (state, action) => {
      const { selectedTenant, selectedOrganization } = action.payload;
      const deletedTenant = state.tenants[selectedOrganization].find(
        (t) => selectedTenant === getTenantId(t.id),
      );

      state.deletingTenant = false;
      state.deleteTenantRequestId = null;
      state.tenants[selectedOrganization] = state.tenants[
        selectedOrganization
      ].filter((t) => selectedTenant !== getTenantId(t.id));
      state.deletedTenant = deletedTenant;
      state.deleteStatus = true;
    },
    [deletePartner.rejected]: (state, action) => {
      state.deletingTenant = null;
      state.deleteTenantRequestId = null;
      state.deletedTenant = null;
      state.deleteStatus = false;
      state.error = action?.payload?.response?.data || null;
    },
  },
});

export const {
  setPartner,
  resetActionStatuses,
  resetStopProcessErrors,
  updateTenantInPartnerList,
} = partners.actions;

export default partners.reducer;
