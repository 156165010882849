import { Grid, styled } from "@mui/material";
import { Button, IconInfo, Tooltip } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

const PREFIX = "InputTooltip";

const classes = {
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(({ theme, color }) => ({
  [`& .${classes.button}`]: {
    marginRight: "8px",
    color: color || theme.palette.text.primary,
  },
}));

const InputTooltip = ({ tooltip, trailingIcon, color }) => {
  const ref = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  if (!tooltip && !trailingIcon) {
    return null;
  }

  return (
    <StyledGrid
      color={color}
      container
      direction="row"
      alignItems="center"
      sx={{
        width: "max-content",
      }}
    >
      <Grid item>
        <Tooltip
          ref={ref}
          content={tooltip}
          open={tooltipOpen}
          userSelect="all"
          placement="top"
        >
          <Button
            className={classes.button}
            leadingIcon={<IconInfo />}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            appearance="ghost"
            small
          />
        </Tooltip>
      </Grid>

      {trailingIcon && <Grid item>{trailingIcon}</Grid>}
    </StyledGrid>
  );
};

InputTooltip.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  helperText: PropTypes.string,
  tooltip: PropTypes.node,
  label: PropTypes.string,
  trailingIcon: PropTypes.node,
};

export default InputTooltip;
