import React from "react";
import ParentRoute from "../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../common/components/ProtectedRoute";
import { componentLoader } from "../common/components/SuspenseComponent";
import { LAYOUTS, SUPPORT_ROLES } from "../common/constants";

const GeneralSettings = React.lazy(() =>
  componentLoader(() => import("../settings/components/GeneralSettings")),
);

const SettingsRoutes = [
  {
    path: "/settings",
    title: "Settings",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    roles: [...SUPPORT_ROLES],
    isTabs: true,
    highlightContent: true,
    isAutoHeight: true,
    routes: [
      {
        path: "/settings/general",
        title: "General",
        isTab: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={GeneralSettings} />
        ),
        // isTab: true,
        isSubComponent: true,
        exact: true,
      },
    ],
  },
];

export default SettingsRoutes;
