import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import { DISPLAY_POOL_NAME_MESSAGE_RESTRICTION } from "../../admin-actions/adminActionsConstants";
import TextInput from "../../common/components/form/TextInput";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import {
  checkExistingPool,
  composeValidators,
  required,
} from "../../common/validators";
import { validatePoolName } from "../onboardingUtils";

export default function PoolNameForm({
  onChange,
  initialValues,
  form = "poolNameForm",
}) {
  const { pools } = useSelector((state) => state.adminActions.pools);
  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const handleOnChange = (e, input, formMeta) => {
    input.onChange(e);
    formMeta.form.change("name", e);
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      validate={(e) => onChange(e, form)}
      render={(formMeta) => {
        return (
          <form onSubmit={formMeta.handleSubmit}>
            <Field
              name="name"
              id="poolName"
              label="Pool Name"
              validate={(value) => {
                const poolNameError = composeValidators(
                  required,
                  validatePoolName,
                )(value);
                if (poolNameError) {
                  return poolNameError;
                }
                const poolExists = checkExistingPool(tenantPools)(value);
                if (poolExists) {
                  return poolExists;
                }
                return undefined;
              }}
              render={({ input, meta }) => {
                const showError = Boolean(
                  meta.error && (meta.touched || input.value),
                );

                return (
                  <TextInput
                    {...input}
                    label="Pool name"
                    sx={{ width: "100%" }}
                    error={showError}
                    helperText={
                      showError
                        ? meta.error
                        : DISPLAY_POOL_NAME_MESSAGE_RESTRICTION
                    }
                    helperTextVisibility="visible"
                    onChange={(e) => handleOnChange(e, input, formMeta)}
                  />
                );
              }}
            />
          </form>
        );
      }}
    />
  );
}
