import { IconCheckmarkCircle, IconClock, IconInfo } from "@veneer/core";
import { formatLabelToCamelCase } from "../../utils";

// TODO: TSW-225873: Once the backend is adjusted to follow the naming convention for the status
// update the TENANT_PROGRESS constant to match the backend
const STATUS_MAP = {
  available: {
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  finalizing: {
    type: "neutral",
    label: "Finalizing",
    icon: <IconInfo size={16} filled />,
  },

  // TODO:Need to change this to pending, initializing its confusing no way to do this in the UI without a lot of
  // code changes due to the filter modal
  initializing: {
    type: "disabled",
    label: "Initializing",
    icon: <IconClock filled />,
  },
  pending: {
    type: "disabled",
    label: "Pending",
    icon: <IconClock filled />,
  },
};

const getStatusKey = (status) => {
  const statusKeys = Object.keys(STATUS_MAP);
  return statusKeys.find(
    (key) =>
      // TODO: TSW-225873: Temporary fix until backend follows guidelines for naming conventions
      key === formatLabelToCamelCase(status),
  );
};

export const mapTenantTagType = (status) => {
  const statusKey = getStatusKey(status);
  return STATUS_MAP[statusKey]?.type || "disabled";
};

export const mapTenantLabel = (status) => {
  const statusKey = getStatusKey(status);
  return STATUS_MAP[statusKey]?.label || "Unknown";
};

export const mapTenantIcon = (status) => {
  const statusKey = getStatusKey(status);
  return STATUS_MAP[statusKey]?.icon || undefined;
};
