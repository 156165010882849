import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

// If an UUID does hot have dashes, add them
const addUuidDashesToId = (itemId) => {
  if (itemId.length === 32) {
    return itemId.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
  }
  return itemId;
};

export const createPool = createAsyncThunk(
  "createPool",
  async (
    { organizationId, tenantId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingCreatePool, createPoolRequestId } =
      getState().adminActions.pools;

    if (!loadingCreatePool || requestId !== createPoolRequestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePool = createAsyncThunk(
  "updatePool",
  async (
    { organizationId, tenantId, poolId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingUpdatePool, updatePoolRequestId } =
      getState().adminActions.pools;
    if (!loadingUpdatePool || requestId !== updatePoolRequestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${poolId}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();

      return { name: payload.name, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPools = createAsyncThunk(
  "getPools",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { pools, loadingPools, poolsRequestId } =
      getState().adminActions.pools;
    if (!loadingPools || requestId !== poolsRequestId) {
      return;
    }
    if (useCache && pools && pools[selectedTenant]) {
      return { data: pools[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/resourcePools`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      // FORCE id to have dashes (temporary fix until backend is fixed and returns the right id format)
      const responseDataWithDashes =
        response?.data?.data &&
        response?.data?.data.map((pool) => {
          return {
            ...pool,
            id: addUuidDashesToId(pool.id),
          };
        });
      response.data.data = responseDataWithDashes;

      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPoolDetail = createAsyncThunk(
  "getPoolDetail",
  async (
    { tenantId, organizationId, poolId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingPoolDetails, poolDetailsRequestId } =
      getState().adminActions.pools;

    if (!loadingPoolDetails || requestId !== poolDetailsRequestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${poolId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDeviceImages = createAsyncThunk(
  "getDeviceImages",
  async (
    { useCache = true, organizationId, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deviceImages, getDeviceImagesRequestId, loadingDeviceImages } =
      getState().adminActions.pools;
    if (!loadingDeviceImages || requestId !== getDeviceImagesRequestId) {
      return;
    }
    if (useCache && deviceImages && deviceImages[selectedTenant]) {
      return {
        data: deviceImages[selectedTenant],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/window365/deviceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getGalleryImages = createAsyncThunk(
  "getGalleryImages",
  async (
    { useCache = true, organizationId, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { galleryImages, getGalleryImagesRequestId, loadingGalleryImages } =
      getState().adminActions.pools;
    if (!loadingGalleryImages || requestId !== getGalleryImagesRequestId) {
      return;
    }
    if (useCache && galleryImages && galleryImages[selectedTenant]) {
      return {
        data: galleryImages[selectedTenant],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/window365/galleryImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUsersForPool = createAsyncThunk(
  "getUsersForPool",
  async (
    { useCache = true, selectedTenant, organizationId, resourcePoolId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { poolUsers, getUsersForPoolRequestId, loadingPoolUsers } =
      getState().adminActions.pools;
    if (!loadingPoolUsers || requestId !== getUsersForPoolRequestId) {
      return;
    }
    if (
      useCache &&
      poolUsers &&
      poolUsers[`${selectedTenant}.${resourcePoolId}`]
    ) {
      return {
        data: poolUsers[`${selectedTenant}.${resourcePoolId}`],
        selectedTenant,
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/resourcePools/${resourcePoolId}/users`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, resourcePoolId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const poolUserAssignment = createAsyncThunk(
  "poolUserAssignment",
  async (
    { selectedTenant, organizationId, resourcePoolId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { poolUserAssignmentRequestId, assigningUserToPool } =
      getState().adminActions.pools;
    if (!assigningUserToPool || requestId !== poolUserAssignmentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/resourcePools/${resourcePoolId}/accessControl/users`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePool = createAsyncThunk(
  "deletePool",
  async (
    { selectedTenant, organizationId, resourcePoolId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deletePoolRequestId, deletingPool } = getState().adminActions.pools;
    if (!deletingPool || requestId !== deletePoolRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/resourcePools/${resourcePoolId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTemplates = createAsyncThunk(
  "getTemplates",
  async (
    { tenantId, organizationId, siteName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getTemplatesRequestId, loadingTemplates } =
      getState().adminActions.pools;
    if (!loadingTemplates || requestId !== getTemplatesRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/templates`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (
    { tenantId, organizationId, useCache },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getUsersRequestId, loadingUsers, users } =
      getState().adminActions.pools;

    if (!loadingUsers || requestId !== getUsersRequestId) {
      return;
    }

    if (useCache && users && users.length > 0) {
      return { data: users };
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/users`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEnrollmentCommand = createAsyncThunk(
  "getEnrollmentCommand",
  async (
    { tenantId, organizationId, poolId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingEnrollmentCommand, enrollmentCommandRequestId } =
      getState().adminActions.pools;
    if (!loadingEnrollmentCommand || requestId !== enrollmentCommandRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${poolId}/enrollmentCommand`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  pools: {},
  pool: {},
  poolUsers: {},

  selectedPool: null,
  selectedSite: null,

  loadingCreatePool: false,
  createPoolRequestId: null,

  loadingPools: false,
  poolsRequestId: null,

  loadingPoolDetails: false,
  poolDetailsRequestId: null,

  loadingUpdatePool: false,
  updatePoolRequestId: null,

  loadingPoolUsers: false,
  getUsersForPoolRequestId: null,

  assigningUserToPool: false,
  poolUserAssignmentRequestId: null,

  deletingPool: false,
  deletePoolRequestId: null,

  templates: [],
  loadingTemplates: false,
  getTemplatesRequestId: null,

  users: [],
  loadingUsers: false,
  getUsersRequestId: null,

  deviceImages: {},
  loadingDeviceImages: false,
  getDeviceImagesRequestId: null,

  galleryImages: {},
  loadingGalleryImages: false,
  getGalleryImagesRequestId: null,

  error: null,
  createPoolSuccess: null,

  loadingEnrollmentCommand: false,
  enrollmentCommandRequestId: null,
  enrollmentCommand: "",
  expirationDateEnrollmentCommand: "",

  poolToDelete: {
    open: false,
    name: "",
    id: "",
  },

  addPoolVsphere: {
    name: null,
    template: null,
    joinDomain: null,
    directoryService: null,
    assignmentPolicy: null,
    provisioningMethod: null,
    assignmentUser: [],
    quantity: null,
    prefix: "WP",
    suffixLength: null,
    suffixNumberingPattern: null,
    username: null,
    password: null,
  },

  addPoolAzure: {
    name: null,
    deviceImage: null,
    entraGroups: [],
    assignLicense: false,
  },
  addPoolRegisteredDevices: {
    name: null,
    persistent: null,
    entraGroups: [],
    targetUsers: [],
    agentType: null,
  },
};

export const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    resetAddPoolRegisteredDevices: (state) => {
      state.addPoolRegisteredDevices = {
        name: null,
        persistent: null,
        entraGroups: [],
        targetUsers: [],
        agentType: null,
      };
    },
    setSelectedPool: (state, action) => {
      state.selectedPool = action.payload;
    },
    resetSelectedPool: (state) => {
      state.selectedPool = null;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    resetSelectedSite: (state) => {
      state.selectedSite = null;
    },
    resetPool: (state) => {
      state.pool = {};
      state.enrollmentCommand = "";
      state.expirationDateEnrollmentCommand = "";
    },
    updateResourcePoolStatus: (state, action) => {
      var payload = action.payload.data;
      var poolData = payload.Data;
      var tenantId = poolData.AnywareTenantId;
      if (state.pools[tenantId]) {
        state.pools[tenantId] = state.pools[tenantId]
          .filter(
            (pool) =>
              !(
                pool.id === poolData.ResourcePoolId &&
                poolData.ResourcePoolStatus === "Deleted"
              ),
          )
          .map((pool) => {
            if (pool.id === poolData.ResourcePoolId) {
              pool.status = poolData.ResourcePoolStatus;
            }
            return pool;
          });
      }

      if (state.pool.id === poolData.ResourcePoolId) {
        state.pool.status = poolData.ResourcePoolStatus;
      }
    },
    resetPoolErrors: (state) => {
      state.deletePoolError = null;
      state.editPoolError = null;
      state.updatePoolError = null;
      state.createPoolError = null;
      state.getPoolDetailError = null;
      state.createStatus = null;
      state.assignUserError = null;
      state.assignUserSuccess = null;
      state.deleteScheduleError = null;
      state.deleteScheduleSuccess = null;
    },
    clearPools: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        if (!action.payload.silentRefresh) {
          state.pools[action.payload.tenantId] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refreshPools = true;
        state.poolsAvailable[action.payload.tenantId] = false;
      }
    },
    resetAddPoolVsphere: (state) => {
      state.addPoolVsphere = initialState.addPoolVsphere;
    },
    setAddPoolName: (state, action) => {
      state.addPoolVsphere.name = action.payload;
    },
    setTemplate: (state, action) => {
      state.addPoolVsphere.template = action.payload;
    },
    setJoinDomain: (state, action) => {
      state.addPoolVsphere.joinDomain = action.payload;
    },
    setDirectoryService: (state, action) => {
      state.addPoolVsphere.directoryService = action.payload;
    },
    setAssignmentPolicy: (state, action) => {
      state.addPoolVsphere.assignmentPolicy = action.payload;
    },
    setProvisioningMethod: (state, action) => {
      state.addPoolVsphere.provisioningMethod = action.payload;
    },
    setAssignmentUser: (state, action) => {
      state.addPoolVsphere.assignmentUser = action.payload;
    },
    setQuantity: (state, action) => {
      state.addPoolVsphere.quantity = action.payload;
    },
    setPrefix: (state, action) => {
      state.addPoolVsphere.prefix = action.payload;
    },
    setSuffixLength: (state, action) => {
      state.addPoolVsphere.suffixLength = action.payload;
    },
    setSuffixNumberingPattern: (state, action) => {
      state.addPoolVsphere.suffixNumberingPattern = action.payload;
    },
    setUsername: (state, action) => {
      state.addPoolVsphere.username = action.payload;
    },
    setPassword: (state, action) => {
      state.addPoolVsphere.password = action.payload;
    },
    resetAddPoolAzure: (state) => {
      state.addPoolAzure = initialState.addPoolAzure;
    },
    setAddPoolNameAzure: (state, action) => {
      state.addPoolAzure.name = action.payload;
    },
    setAddPoolNameRD: (state, action) => {
      state.addPoolRegisteredDevices.name = action.payload;
    },
    setAddPersistentRD: (state, action) => {
      state.addPoolRegisteredDevices.persistent = action.payload;
    },
    setAddEntraGroupsRD: (state, action) => {
      state.addPoolRegisteredDevices.entraGroups = action.payload;
    },
    setAddTargetUsersRD: (state, action) => {
      state.addPoolRegisteredDevices.targetUsers = action.payload;
    },
    setAddAgentTypeRD: (state, action) => {
      state.addPoolRegisteredDevices.agentType = action.payload;
    },
    setDeviceImageAzure: (state, action) => {
      state.addPoolAzure.deviceImage = action.payload;
    },
    setEntraGroupsAzure: (state, action) => {
      state.addPoolAzure.entraGroups = action.payload;
    },
    setAssignLicenseAzure: (state, action) => {
      state.addPoolAzure.assignLicense = action.payload;
    },
    setSelectedPoolToDelete: (state, action) => {
      state.poolToDelete = action.payload;
    },
    resetPoolToDelete: (state) => {
      state.poolToDelete = {
        open: false,
        name: "",
        id: "",
      };
    },
  },
  extraReducers: {
    [getPools.pending]: (state, action) => {
      state.loadingPools = true;
      state.poolsRequestId = action.meta.requestId;
      state.error = null;
    },
    [getPools.fulfilled]: (state, action) => {
      state.poolsRequestId = null;
      state.loadingPools = false;
      if (action.payload.selectedTenant && action.payload?.data) {
        _.set(
          state.pools,
          `${action.payload.selectedTenant}`,
          action.payload?.data,
        );
      }
    },
    [getPools.rejected]: (state, action) => {
      state.poolsRequestId = null;
      state.error = action.payload;
      state.loadingPools = false;
    },

    [getUsersForPool.pending]: (state, action) => {
      state.loadingPoolUsers = true;
      state.getUsersForPoolRequestId = action.meta.requestId;
      state.error = null;
    },
    [getUsersForPool.fulfilled]: (state, action) => {
      state.getUsersForPoolRequestId = null;
      state.loadingPoolUsers = false;
      if (action.payload.selectedTenant && action.payload.resourcePoolId) {
        _.set(
          state.poolUsers,
          `${action.payload.selectedTenant}.${action.payload.resourcePoolId}`,
          action.payload.data,
        );
      }
    },
    [getUsersForPool.rejected]: (state, action) => {
      state.getUsersForPoolRequestId = null;
      state.error = action.payload;
      state.loadingPoolUsers = false;
    },

    [poolUserAssignment.pending]: (state, action) => {
      state.assigningUserToPool = true;
      state.poolUserAssignmentRequestId = action.meta.requestId;
      state.error = null;
    },
    [poolUserAssignment.fulfilled]: (state, action) => {
      state.poolUserAssignmentRequestId = null;
      state.assigningUserToPool = false;
      if (action.payload.selectedTenant && action.payload.resourcePoolId) {
        _.set(
          state.poolUsers,
          `${action.payload.selectedTenant}.${action.payload.resourcePoolId}`,
          action.payload.data,
        );
      }
    },
    [poolUserAssignment.rejected]: (state, action) => {
      state.poolUserAssignmentRequestId = null;
      state.error = action.payload;
      state.assigningUserToPool = false;
    },
    [deletePool.pending]: (state, action) => {
      state.deletingPool = true;
      state.deletePoolRequestId = action.meta.requestId;
      state.error = null;
    },
    [deletePool.fulfilled]: (state, action) => {
      state.deletePoolRequestId = null;
      state.deletingPool = false;
      if (action.payload.selectedTenant) {
        _.set(
          state.pools,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
      }
    },
    [deletePool.rejected]: (state, action) => {
      state.deletePoolRequestId = null;
      state.error = action.payload;
      state.deletingPool = false;
    },
    [getDeviceImages.pending]: (state, action) => {
      state.loadingDeviceImages = true;
      state.getDeviceImagesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getDeviceImages.fulfilled]: (state, action) => {
      state.getDeviceImagesRequestId = null;
      state.loadingDeviceImages = false;
      if (action.payload.selectedTenant && action.payload?.data) {
        _.set(
          state.deviceImages,
          `${action.payload.selectedTenant}`,
          action.payload?.data,
        );
      }
    },
    [getDeviceImages.rejected]: (state, action) => {
      state.getDeviceImagesRequestId = null;
      state.error = action.payload;
      state.loadingDeviceImages = false;
    },

    [getGalleryImages.pending]: (state, action) => {
      state.loadingGalleryImages = true;
      state.getGalleryImagesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.getGalleryImagesRequestId = null;
      state.loadingGalleryImages = false;
      if (action.payload.selectedTenant && action.payload?.data) {
        _.set(
          state.galleryImages,
          `${action.payload.selectedTenant}`,
          action.payload?.data,
        );
      }
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.getGalleryImagesRequestId = null;
      state.error = action.payload;
      state.loadingGalleryImages = false;
    },

    [getTemplates.pending]: (state, action) => {
      state.loadingTemplates = true;
      state.getTemplatesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getTemplates.fulfilled]: (state, action) => {
      state.getTemplatesRequestId = null;
      state.loadingTemplates = false;
      if (action?.payload?.data) {
        state.templates = action.payload.data;
      }
    },
    [getTemplates.rejected]: (state, action) => {
      state.getTemplatesRequestId = null;
      state.error = action.payload;
      state.loadingTemplates = false;
    },

    [getUsers.pending]: (state, action) => {
      state.loadingUsers = true;
      state.getUsersRequestId = action.meta.requestId;
      state.error = null;
    },

    [getUsers.fulfilled]: (state, action) => {
      state.getUsersRequestId = null;
      state.loadingUsers = false;
      if (action?.payload?.data) {
        state.users = action.payload.data;
      }
    },

    [getUsers.rejected]: (state, action) => {
      state.getUsersRequestId = null;
      state.error = action.payload;
      state.loadingUsers = false;
    },
    [createPool.pending]: (state, action) => {
      state.loadingCreatePool = true;
      state.createPoolRequestId = action.meta.requestId;
      state.error = null;
      state.createPoolSuccess = null;
    },
    [createPool.fulfilled]: (state, action) => {
      state.createPoolRequestId = null;
      state.loadingCreatePool = false;
      if (action.payload.tenantId && action.payload.data) {
        state.pools[action.payload.tenantId].push(action.payload.data);
        state.pool = action.payload.data;
      }
      state.createPoolSuccess = true;
    },
    [createPool.rejected]: (state, action) => {
      state.createPoolRequestId = null;
      state.error = action.payload;
      state.loadingCreatePool = false;
      state.createPoolSuccess = false;
    },
    [getPoolDetail.pending]: (state, action) => {
      state.loadingPoolDetails = true;
      state.poolDetailsRequestId = action.meta.requestId;
      state.error = null;
    },
    [getPoolDetail.fulfilled]: (state, action) => {
      state.poolDetailsRequestId = null;
      state.loadingPoolDetails = false;
      if (action.payload.tenantId && action.payload.data) {
        state.pool = action.payload.data;
      }
    },
    [getPoolDetail.rejected]: (state, action) => {
      state.poolDetailsRequestId = null;
      state.error = action.payload;
      state.loadingPoolDetails = false;
    },
    [updatePool.pending]: (state, action) => {
      state.loadingUpdatePool = true;
      state.updatePoolRequestId = action.meta.requestId;
      state.error = null;
    },
    [updatePool.fulfilled]: (state, action) => {
      state.updatePoolRequestId = null;
      state.loadingUpdatePool = false;
      if (action.payload.tenantId && action.payload.name) {
        state.pool.name = action.payload.name;

        if (state.pools[action.payload.tenantId]) {
          state.pools[action.payload.tenantId] = state.pools[
            action.payload.tenantId
          ].map((pool) => {
            if (pool.id === state.pool.id) {
              return { ...pool, name: action.payload.name };
            }
            return pool;
          });
        }
      }
    },
    [updatePool.rejected]: (state, action) => {
      state.updatePoolRequestId = null;
      state.error = action.payload;
      state.loadingUpdatePool = false;
    },
    [getEnrollmentCommand.pending]: (state, action) => {
      state.loadingEnrollmentCommand = true;
      state.enrollmentCommandRequestId = action.meta.requestId;
      state.error = null;
    },
    [getEnrollmentCommand.fulfilled]: (state, action) => {
      state.enrollmentCommandRequestId = null;
      state.loadingEnrollmentCommand = false;
      if (action.payload.data?.command && action.payload.data?.expirationDate) {
        state.enrollmentCommand = action.payload.data?.command;
        state.expirationDateEnrollmentCommand =
          action.payload.data?.expirationDate;
      }
    },
    [getEnrollmentCommand.rejected]: (state, action) => {
      state.enrollmentCommandRequestId = null;
      state.error = action.payload;
      state.loadingEnrollmentCommand = false;
    },
  },
});

export const {
  resetPoolErrors,
  resetPool,
  clearPools,
  setSelectedPool,
  resetSelectedPool,
  setSelectedSite,
  resetSelectedSite,
  resetAddPoolVsphere,
  setAddPoolName,
  setTemplate,
  setDirectoryService,
  setJoinDomain,
  setAssignmentPolicy,
  setProvisioningMethod,
  setAssignmentUser,
  setQuantity,
  setPrefix,
  setSuffixLength,
  setSuffixNumberingPattern,
  setUsername,
  setPassword,
  resetAddPoolAzure,
  setAddPoolNameAzure,
  setDeviceImageAzure,
  setEntraGroupsAzure,
  setAssignLicenseAzure,
  updateResourcePoolStatus,
  setAddPoolNameRD,
  setAddPersistentRD,
  setAddEntraGroupsRD,
  setAddTargetUsersRD,
  setAddAgentTypeRD,
  resetAddPoolRegisteredDevices,
  setSelectedPoolToDelete,
  resetPoolToDelete,
} = poolsSlice.actions;

export default poolsSlice.reducer;
