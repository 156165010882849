import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { filterActiveMachines, getMachineName } from "../dashboardUtils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const connectToMachine = createAsyncThunk(
  "connectToMachine",
  async (
    { selectedTenant, organizationId, machineName, siteName, techUser = false },
    { getState, requestId, rejectWithValue },
  ) => {
    const { connectToMachineRequestId, connectingToMachine } =
      getState().dashboard.machines;
    if (!connectingToMachine || requestId !== connectToMachineRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineName}/connect?techUser=${techUser}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const logOffUserSessionInMachine = createAsyncThunk(
  "logOffUserSessionInMachine",
  async (
    { organizationId, tenantId, userSessions, machineName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loggingOffRequestId, loggingOff } = getState().dashboard.machines;
    if (!loggingOff || requestId !== loggingOffRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/spotGroups/userSessions`,
        method: HTTP_METHODS.DELETE,
        data: userSessions,
      }).callAuthorizedAPI();
      return {
        data: response.data,
        tenantId,
        machineName,
        ids: userSessions.map((s) => s.id),
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const rebootMachine = createAsyncThunk(
  "rebootMachine",
  async (
    { selectedTenant, organizationId, machineName, siteName, forceReboot },
    { getState, requestId, rejectWithValue },
  ) => {
    const { rebootMachineRequestId, requestingReboot } =
      getState().dashboard.machines;
    if (!requestingReboot || requestId !== rebootMachineRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineName}/reboot?force=${forceReboot}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const triggerMachineAction = createAsyncThunk(
  "triggerMachineAction",
  async (
    {
      selectedTenant,
      organizationId,
      machineName,
      siteName,
      forceReboot,
      type,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { requestId: stateRequestId, requesting } =
      getState().dashboard.machines.actionsStatus[type];
    if (!requesting || requestId !== stateRequestId) {
      return;
    }
    try {
      const endpoint = `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineName}/manage`;

      const response = await new HTTPClient({
        endpoint,
        method: HTTP_METHODS.PUT,
        data: {
          forceStop: forceReboot,
          command:
            type === "forceStop"
              ? "STOP"
              : isNaN(type)
                ? type.toUpperCase()
                : type,
        },
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const enableDisableLogins = createAsyncThunk(
  "enableDisableLogins",
  async (
    {
      selectedTenant,
      organizationId,
      siteName,
      spotGroupName,
      sessionHostName,
      allowNewSession,
      machineName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { enableDisableLoginsRequestId, requestingLogins } =
      getState().dashboard.machines;
    if (!requestingLogins || requestId !== enableDisableLoginsRequestId) {
      return;
    }
    const data = { allowNewSession: allowNewSession };
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/spotGroups/${spotGroupName}/sessionHosts/${sessionHostName}/sessions`,
        method: HTTP_METHODS.PUT,
        data: data,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, machineName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMachines = createAsyncThunk(
  "getMachines",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, machines } =
      getState().dashboard.machines;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (useCache && machines && machines[selectedTenant]) {
      return { data: machines[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/virtualMachines`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBusinessServers = createAsyncThunk(
  "getBusinessServers",
  async (
    { useCache = true, selectedTenant, organizationId, type },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingBusinessServers, businessServers } =
      getState().dashboard.machines;
    if (!loadingBusinessServers || requestId !== currentRequestId) {
      return;
    }
    if (useCache && businessServers && businessServers[selectedTenant]) {
      return { data: businessServers[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/virtualMachines?type=${type}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMachine = createAsyncThunk(
  "getMachine",
  async (
    { useCache = true, selectedTenant, organizationId, siteName, machineName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, machine } =
      getState().dashboard.machines;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    const machineNameStripped = getMachineName(machineName);
    if (
      useCache &&
      machine[selectedTenant] &&
      machine[selectedTenant][machineNameStripped]
    ) {
      return {
        data: machine[selectedTenant][machineNameStripped],
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/virtualMachines/${machineNameStripped}/sites/${siteName}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedTenant,
        machineName: machineNameStripped,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMachineSessions = createAsyncThunk(
  "getMachineSessions",
  async (
    { useCache = true, selectedTenant, organizationId, siteName, machineName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { sessionsRequestId, loadingMachineSessions, machineSessions } =
      getState().dashboard.machines;
    if (!loadingMachineSessions || requestId !== sessionsRequestId) {
      return;
    }
    const machineNameStripped = getMachineName(machineName);
    if (
      useCache &&
      machineSessions[selectedTenant] &&
      machineSessions[selectedTenant][machineNameStripped]
    ) {
      return {
        data: machineSessions[selectedTenant][machineNameStripped],
        cachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineNameStripped}/users/sessions`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedTenant,
        machineName: machineNameStripped,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteMachine = createAsyncThunk(
  "deleteMachine",
  async (
    { selectedTenant, organizationId, siteName, machineName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { deleteMachineRequestId, deletingMachine } =
      getState().dashboard.machines;
    if (!deletingMachine || requestId !== deleteMachineRequestId) {
      return;
    }
    const machineNameStripped = getMachineName(machineName);

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineNameStripped}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return {
        ...response.data,
        selectedTenant,
        machineName: machineNameStripped,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const assignMachine = createAsyncThunk(
  "assignMachine",
  async (
    { selectedTenant, organizationId, siteName, machineName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { assignMachineRequestId, assigningMachine } =
      getState().dashboard.machines;
    if (!assigningMachine || requestId !== assignMachineRequestId) {
      return;
    }
    const machineNameStripped = getMachineName(machineName);

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/virtualMachines/${machineNameStripped}/assign`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  machines: {},
  businessServers: {},
  machine: {},
  activeMachines: {},
  loading: false,
  loadingBusinessServers: false,
  error: null,
  currentRequestId: null,
  connectingToMachine: null,
  connectToMachineRequestId: null,
  loaderText: "",
  connectoToMachineError: null,
  rebootMachineRequestId: null,
  requestingReboot: null,
  errorWhileRebooting: null,
  rebootRequested: null,
  openRequestRebootModal: false,
  enableDisableLoginsRequestId: null,
  requestingLogins: null,
  errorWhileEnablingDisablingLogins: null,
  loginsRequested: null,
  openRequestLoginsModal: {},

  loadingMachineSessions: null,
  sessionsRequestId: null,
  machineSessions: {},

  deletingMachine: null,
  deleteMachineRequestId: null,
  deleteMachineError: null,
  deleteMachineSuccess: null,

  refreshMachines: false,
  silentRefresh: false,

  loggingOff: null,
  loggingOffRequestId: null,
  loggingOffError: null,

  assigningMachine: null,
  assignMachineRequestId: null,
  assignMachineError: null,
  assignMachineSuccess: null,

  actionsStatus: {
    stop: {
      requestId: null,
      requesting: null,
      error: null,
      requested: null,
    },
    start: {
      requestId: null,
      requesting: null,
      error: null,
      requested: null,
    },
    reboot: {
      requestId: null,
      requesting: null,
      error: null,
      requested: null,
    },
    forceStop: {
      requestId: null,
      requesting: null,
      error: null,
      requested: null,
    },
    delete: {
      requestId: null,
      requesting: null,
      error: null,
      requested: null,
    },
  },
};

export const machineSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {
    setMachines: (state, action) => {
      state.machines[action.payload.selectedTenant] = action.payload.data;
    },
    setMachine: (state, action) => {
      state.machine = action.payload;
    },
    clearMachineUserSessions: (state) => {
      state.machineSessions = {};
    },
    resetErrors: (state) => {
      state.connectoToMachineError = null;
      state.errorWhileRebooting = null;
      state.loaderText = "";
      state.rebootRequested = null;
      state.error = null;
      state.deleteMachineSuccess = null;
      state.deletingMachine = null;
      state.deleteMachineError = null;
      state.deleteMachineSuccess = null;
      state.refreshMachines = false;
      state.silentRefresh = false;

      state.actionsStatus.reboot.error = null;
      state.actionsStatus.reboot.requested = null;
      state.actionsStatus.start.error = null;
      state.actionsStatus.start.requested = null;
      state.actionsStatus.stop.error = null;
      state.actionsStatus.stop.requested = null;
    },
    resetActionErrors: (state) => {
      state.errorWhileRebooting = null;

      state.actionsStatus.reboot.error = null;
      state.actionsStatus.start.error = null;
      state.actionsStatus.stop.error = null;
    },
    setOpenRequestRebootModal: (state, action) => {
      state.openRequestRebootModal = action.payload;
    },
    setOpenRequestLoginsModal: (state, action) => {
      state.openRequestLoginsModal = action.payload;
    },
    clearMachines: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        if (!action.payload.silentRefresh) {
          state.machines[action.payload.tenantId] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refreshMachines = true;
      }
    },
    updateMachineStatus: (state, action) => {
      var payload = action.payload.data;
      var machineData = payload.Data;
      var tenantId = machineData.AnywareTenantId;
      let machineFound = false;
      if (state.machines[tenantId]) {
        state.machines[tenantId] = state.machines[tenantId]
          .filter(
            (machine) =>
              !(
                (machine.name === machineData.ServerName &&
                  machineData.ServerStatus === "Deleting") ||
                machineData.ServerStatus === "Deleted"
              ),
          )
          .map((machine) => {
            if (machine.name === machineData.ServerName) {
              machine.properties.status = machineData.ServerStatus;
              machine.properties.runningStatus = machineData.ServerOnline
                ? "Running"
                : "Stopped";
              machineFound = true;
            }
            return machine;
          });
      }
      if (!state.machines[tenantId] || !machineFound) {
        state.refreshMachines = true;
      }
    },
  },
  extraReducers: {
    [getMachines.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
      state.refreshMachines = false;
    },
    [getMachines.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      const mdata = action.payload?.data.map((machine) => {
        const updatedMachine = {
          ...machine,
          properties: {
            ...machine.properties,
            runningStatus: machine?.properties?.online ? "Running" : "Stopped",
          },
        };
        return updatedMachine;
      });
      state.machines[action.payload?.selectedTenant] = mdata || [];
      state.activeMachines[action.payload?.selectedTenant] =
        filterActiveMachines(mdata || []);
      state.silentRefresh = false;
    },
    [getMachines.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loading = false;
      state.silentRefresh = false;
    },
    [getBusinessServers.pending]: (state, action) => {
      state.loadingBusinessServers = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getBusinessServers.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingBusinessServers = false;
      state.businessServers[action.payload?.selectedTenant] =
        action.payload?.data || [];
    },
    [getBusinessServers.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loadingBusinessServers = false;
    },
    [getMachineSessions.pending]: (state, action) => {
      state.loadingMachineSessions = true;
      state.sessionsRequestId = action.meta.requestId;
    },
    [getMachineSessions.fulfilled]: (state, action) => {
      state.sessionsRequestId = null;
      state.loadingMachineSessions = false;
      if (!action.payload.cachedData) {
        _.set(
          state.machineSessions,
          `${action.payload?.selectedTenant}.${action.payload?.machineName}`,
          action.payload?.data || [],
        );
      }
    },
    [getMachineSessions.rejected]: (state, action) => {
      state.sessionsRequestId = null;
      state.error = action.payload || true;
      state.loadingMachineSessions = false;
    },
    [getMachine.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getMachine.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      if (!action.payload.cachedData) {
        _.set(
          state.machine,
          `${action.payload?.selectedTenant}.${action.payload?.machineName?.toLowerCase()}`,
          action.payload?.data || [],
        );
      }
    },
    [getMachine.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload || true;
      state.loading = false;
      state.machine = null;
    },
    [enableDisableLogins.pending]: (state, action) => {
      state.loading = true;
      state.enableDisableLoginsRequestId = action.meta.requestId;
      state.requestingLogins = true;
      state.loginsRequested = null;
    },
    [enableDisableLogins.fulfilled]: (state, action) => {
      state.requestingLogins = false;
      state.enableDisableLoginsRequestId = null;
      state.loading = false;
      _.set(
        state.machine,
        `${
          action.payload.selectedTenant
        }.${action.payload.machineName.toLowerCase()}.sessionHostProperties`,
        action.payload?.data,
      );
      state.loginsRequested = true;
    },
    [enableDisableLogins.rejected]: (state, action) => {
      state.requestingLogins = false;
      state.enableDisableLoginsRequestId = null;
      state.errorWhileEnablingDisablingLogins = action.payload || true;
      state.loginsRequested = null;
    },
    [connectToMachine.pending]: (state, action) => {
      state.connectingToMachine = true;
      state.connectToMachineRequestId = action.meta.requestId;
      state.loaderText = "Connecting To Machine";
      state.connectoToMachineError = null;
    },
    [connectToMachine.fulfilled]: (state) => {
      state.connectingToMachine = false;
      state.connectToMachineRequestId = null;
      state.loaderText = "";
      state.connectoToMachineError = null;
    },
    [connectToMachine.rejected]: (state, action) => {
      state.connectingToMachine = false;
      state.connectToMachineRequestId = null;
      state.loaderText = "";
      state.connectoToMachineError = action.payload?.message || true;
    },
    [rebootMachine.pending]: (state, action) => {
      state.requestingReboot = true;
      state.rebootMachineRequestId = action.meta.requestId;
      state.rebootRequested = null;
      state.errorWhileRebooting = null;
    },
    [rebootMachine.fulfilled]: (state) => {
      state.requestingReboot = false;
      state.rebootMachineRequestId = null;
      state.rebootRequested = true;
      state.errorWhileRebooting = null;
    },
    [rebootMachine.rejected]: (state, action) => {
      state.requestingReboot = false;
      state.rebootMachineRequestId = null;
      state.rebootRequested = null;
      state.errorWhileRebooting = action.payload?.message || true;
    },
    [deleteMachine.pending]: (state, action) => {
      state.deletingMachine = true;
      state.deleteMachineError = null;
      state.deleteMachineRequestId = action.meta.requestId;
      state.deleteMachineSuccess = null;
    },
    [deleteMachine.fulfilled]: (state, action) => {
      state.deleteMachineSuccess = true;
      state.deletingMachine = false;
      state.deleteMachineError = null;
      state.deleteMachineRequestId = null;
      if (state.machines[action.payload.selectedTenant]) {
        state.machines[action.payload.selectedTenant] = state.machines[
          action.payload.selectedTenant
        ].filter((u) => getMachineName(u.name) !== action.payload.machineName);
      }
      state.businessServers[action.payload.selectedTenant] =
        state.businessServers[action.payload.selectedTenant].filter(
          (u) =>
            getMachineName(u.name) !== action.payload.machineName.toLowerCase(),
        );
    },
    [deleteMachine.rejected]: (state, action) => {
      state.deletingMachine = null;
      state.deleteMachineError = action.payload;
      state.deleteMachineRequestId = null;
      state.deleteMachineSuccess = false;
    },
    [logOffUserSessionInMachine.pending]: (state, action) => {
      state.loggingOff = true;
      state.loggingOffRequestId = action.meta.requestId;
      state.loggingOffError = null;
    },
    [logOffUserSessionInMachine.fulfilled]: (state, action) => {
      state.loggingOffRequestId = null;
      state.loggingOff = false;
      state.loggingOffError = null;
      state.machineSessions[action.payload?.tenantId][
        action.payload?.machineName
      ] = state.machineSessions[action.payload?.tenantId][
        action.payload?.machineName
      ].filter((s) => action.payload.ids.indexOf(s.id) === -1);
    },
    [logOffUserSessionInMachine.rejected]: (state, action) => {
      state.loggingOffRequestId = null;
      state.loggingOffError = action.payload;
      state.loggingOff = false;
    },
    [triggerMachineAction.pending]: (state, action) => {
      const type = action.meta.arg.type;

      state.actionsStatus[type].requesting = true;
      state.actionsStatus[type].requestId = action.meta.requestId;
      state.actionsStatus[type].requested = null;
      state.actionsStatus[type].error = null;
    },
    [triggerMachineAction.fulfilled]: (state, action) => {
      const type = action.meta.arg.type;

      state.actionsStatus[type].requesting = false;
      state.actionsStatus[type].requestId = null;
      state.actionsStatus[type].requested = true;
      state.actionsStatus[type].error = null;
    },
    [triggerMachineAction.rejected]: (state, action) => {
      const type = action.meta.arg.type;

      state.actionsStatus[type].requesting = false;
      state.actionsStatus[type].requestId = null;
      state.actionsStatus[type].requested = null;
      state.actionsStatus[type].error =
        action.payload?.message ||
        action.payload?.detail ||
        action.error.message ||
        true;
    },
    [assignMachine.pending]: (state, action) => {
      state.assigningMachine = true;
      state.assignMachineError = null;
      state.assignMachineRequestId = action.meta.requestId;
      state.assignMachineSuccess = null;
    },
    [assignMachine.fulfilled]: (state) => {
      state.assignMachineSuccess = true;
      state.assigningMachine = false;
      state.assignMachineError = null;
      state.assignMachineRequestId = null;
    },
    [assignMachine.rejected]: (state, action) => {
      state.assigningMachine = null;
      state.assignMachineError = action.payload;
      state.assignMachineRequestId = null;
      state.assignMachineSuccess = false;
    },
  },
});

export const machineSelector = (state) => state.dashboard.machines.machine;

export const {
  setMachines,
  setMachine,
  resetErrors,
  setOpenRequestRebootModal,
  setOpenRequestLoginsModal,
  clearMachines,
  clearMachineUserSessions,
  resetActionErrors,
  updateMachineStatus,
} = machineSlice.actions;

export default machineSlice.reducer;
