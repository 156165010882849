import { primitives } from "@veneer/primitives";
import { extendSemantics } from "@veneer/semantics";

// Wex tokens collected from figma:
// https://www.figma.com/file/T5EndUmpj0fj7NmZrPOIVn/WEX-Tokens?type=design&node-id=0%3A1&mode=design&t=9vmbogzFvAUopOnS-1

/**
 * Applies opacity to a given hex color and returns the corresponding rgba color.
 *
 * @param {string} hexColor - The hex color value.
 * @param {number} opacity - The opacity value as a percentage (0 to 100).
 * @returns {string} The rgba color value.
 */
export const applyOpacity = (hexColor, opacity) => {
  // Convert hex to RGB
  const bigint = parseInt(hexColor.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Convert opacity from percentage to a value between 0 and 1
  const normalizedOpacity = opacity / 100;

  // Return rgba color
  return `rgba(${r},${g},${b},${normalizedOpacity})`;
};

// Light theme

const lightForeground = {
  default: primitives.color.gray12,
  medium: primitives.color.gray9,
  light: primitives.color.gray7,
  disabled: primitives.color.gray5,
};

const lightBackground = {
  default: applyOpacity(primitives.color.darkNavy5, primitives.color.opacity1),
  medium: primitives.color.gray2,
  disabled: primitives.color.gray3,
  container: primitives.color.white,
  containerHover: primitives.color.gray1,
  containerActive: primitives.color.gray2,
  stroke: primitives.color.darkNavy0,
};

const lightPrimary = {
  default: primitives.color.darkNavy7,
  hover: primitives.color.darkNavy8,
  active: primitives.color.darkNavy9,
  backgroundDefault: primitives.color.darkNavy0,
  backgroundHover: applyOpacity(
    primitives.color.darkNavy7,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.darkNavy7,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.white,
};

const lightNeutral = {
  default: primitives.color.gray7,
  hover: primitives.color.gray8,
  active: primitives.color.gray9,
  backgroundDefault: primitives.color.gray1,
  backgroundHover: applyOpacity(
    primitives.color.gray5,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.gray5,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.white,
};

const lightPositive = {
  default: primitives.color.green7,
  hover: primitives.color.green8,
  active: primitives.color.green9,
  backgroundDefault: primitives.color.green0,
  backgroundHover: primitives.color.green1,
  backgroundActive: primitives.color.green2,
  foreground: primitives.color.white,
};

const lightCaution = {
  default: primitives.color.gold3,
  hover: primitives.color.gold4,
  active: primitives.color.gold5,
  backgroundDefault: primitives.color.gold0,
  backgroundHover: primitives.color.gold1,
  backgroundActive: primitives.color.gold2,
  foreground: primitives.color.gold9,
  foregroundHover: primitives.color.gray12,
  foregroundActive: primitives.color.gray12,
};

const lightWarning = {
  default: primitives.color.darkOrange5,
  hover: primitives.color.darkOrange6,
  active: primitives.color.darkOrange7,
  backgroundDefault: primitives.color.darkOrange0,
  backgroundHover: primitives.color.darkOrange1,
  backgroundActive: primitives.color.darkOrange2,
  foreground: primitives.color.gray12,
};

const lightNegative = {
  default: primitives.color.red7,
  hover: primitives.color.red8,
  active: primitives.color.red9,
  backgroundDefault: primitives.color.red0,
  backgroundHover: primitives.color.red1,
  backgroundActive: primitives.color.red2,
  foreground: primitives.color.white,
};

const lightHelp = {
  default: primitives.color.vividSkyBlue7,
  hover: primitives.color.vividSkyBlue8,
  active: primitives.color.vividSkyBlue9,
  backgroundDefault: primitives.color.vividSkyBlue0,
  backgroundHover: primitives.color.vividSkyBlue1,
  backgroundActive: primitives.color.vividSkyBlue2,
  foreground: primitives.color.white,
};

// Dark theme

const darkForeground = {
  default: primitives.color.white,
  medium: primitives.color.gray3,
  light: primitives.color.gray5,
  disabled: primitives.color.gray7,
};

const darkBackground = {
  default: primitives.color.gray12,
  medium: primitives.color.gray10,
  disabled: primitives.color.gray9,
  container: primitives.color.gray11,
  containerHover: primitives.color.gray10,
  containerActive: primitives.color.gray9,
  stroke: primitives.color.gray9,
};

const darkPrimary = {
  default: primitives.color.darkNavy5,
  hover: primitives.color.darkNavy4,
  active: primitives.color.darkNavy3,
  backgroundDefault: primitives.color.darkNavy12,
  backgroundHover: applyOpacity(
    primitives.color.darkNavy5,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.darkNavy5,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.gray12,
};

const darkNeutral = {
  default: primitives.color.gray5,
  hover: primitives.color.gray4,
  active: primitives.color.gray3,
  backgroundDefault: primitives.color.gray10,
  backgroundHover: applyOpacity(
    primitives.color.gray6,
    primitives.color.opacity1,
  ),
  backgroundActive: applyOpacity(
    primitives.color.gray6,
    primitives.color.opacity2,
  ),
  foreground: primitives.color.gray12,
};

const darkPositive = {
  default: primitives.color.green5,
  hover: primitives.color.green4,
  active: primitives.color.green3,
  backgroundDefault: primitives.color.green12,
  backgroundHover: primitives.color.green11,
  backgroundActive: primitives.color.green10,
  foreground: primitives.color.gray12,
};

const darkCaution = {
  default: primitives.color.gold3,
  hover: primitives.color.gold2,
  active: primitives.color.gold1,
  backgroundDefault: primitives.color.gold12,
  backgroundHover: primitives.color.gold11,
  backgroundActive: primitives.color.gold10,
  foreground: primitives.color.gray12,
};

const darkWarning = {
  default: primitives.color.darkOrange5,
  hover: primitives.color.darkOrange4,
  active: primitives.color.darkOrange3,
  backgroundDefault: primitives.color.darkOrange12,
  backgroundHover: primitives.color.darkOrange11,
  backgroundActive: primitives.color.darkOrange10,
  foreground: primitives.color.white,
};

const darkNegative = {
  default: primitives.color.red5,
  hover: primitives.color.red4,
  active: primitives.color.red3,
  backgroundDefault: primitives.color.red12,
  backgroundHover: primitives.color.red11,
  backgroundActive: primitives.color.red10,
  foreground: primitives.color.gray12,
};

const darkHelp = {
  default: primitives.color.vividSkyBlue5,
  hover: primitives.color.vividSkyBlue4,
  active: primitives.color.vividSkyBlue3,
  backgroundDefault: primitives.color.vividSkyBlue12,
  backgroundHover: primitives.color.vividSkyBlue11,
  backgroundActive: primitives.color.vividSkyBlue10,
  foreground: primitives.color.gray12,
};

const wexTheme = extendSemantics({
  color: {
    light: {
      foreground: {
        default: lightForeground.default,
        medium: lightForeground.medium,
        light: lightForeground.light,
        disabled: lightForeground.disabled,
      },
      background: {
        default: lightBackground.default,
        medium: lightBackground.medium,
        disabled: lightBackground.disabled,
        container: {
          default: lightBackground.container,
          hover: lightBackground.containerHover,
          active: lightBackground.containerActive,
        },
        containerHover: lightBackground.containerHover,
        containerActive: lightBackground.containerActive,
        stroke: lightBackground.stroke,
      },
      primary: {
        base: {
          default: lightPrimary.default,
          hover: lightPrimary.hover,
          active: lightPrimary.active,
        },
        background: {
          default: lightPrimary.backgroundDefault,
          hover: lightPrimary.backgroundHover,
          active: lightPrimary.backgroundActive,
        },
        foreground: {
          default: lightPrimary.foreground,
          hover: lightPrimary.foreground,
          active: lightPrimary.foreground,
        },
      },
      neutral: {
        base: {
          default: lightNeutral.default,
          hover: lightNeutral.hover,
          active: lightNeutral.active,
        },
        background: {
          default: lightNeutral.backgroundDefault,
          hover: lightNeutral.backgroundHover,
          active: lightNeutral.backgroundActive,
        },
        foreground: {
          default: lightNeutral.foreground,
          hover: lightNeutral.foreground,
          active: lightNeutral.foreground,
        },
      },
      stroke: { default: lightBackground.stroke },
      positive: {
        base: {
          default: lightPositive.default,
          hover: lightPositive.hover,
          active: lightPositive.active,
        },
        background: {
          default: lightPositive.backgroundDefault,
          hover: lightPositive.backgroundHover,
          active: lightPositive.backgroundActive,
        },
        foreground: {
          default: lightPositive.foreground,
          hover: lightPositive.foreground,
          active: lightPositive.foreground,
        },
      },
      caution: {
        base: {
          default: lightCaution.default,
          hover: lightCaution.hover,
          active: lightCaution.active,
        },
        background: {
          default: lightCaution.backgroundDefault,
          hover: lightCaution.backgroundHover,
          active: lightCaution.backgroundActive,
        },
        foreground: {
          default: lightCaution.foreground,
          hover: lightCaution.foreground,
          active: lightCaution.foreground,
        },
      },
      warning: {
        base: {
          default: lightWarning.default,
          hover: lightWarning.hover,
          active: lightWarning.active,
        },
        background: {
          default: lightWarning.backgroundDefault,
          hover: lightWarning.backgroundHover,
          active: lightWarning.backgroundActive,
        },
        foreground: {
          default: lightWarning.foreground,
          hover: lightWarning.foreground,
          active: lightWarning.foreground,
        },
      },
      negative: {
        base: {
          default: lightNegative.default,
          hover: lightNegative.hover,
          active: lightNegative.active,
        },
        background: {
          default: lightNegative.backgroundDefault,
          hover: lightNegative.backgroundHover,
          active: lightNegative.backgroundActive,
        },
        foreground: {
          default: lightNegative.foreground,
          hover: lightNegative.foreground,
          active: lightNegative.foreground,
        },
      },
      help: {
        base: {
          default: lightHelp.default,
          hover: lightHelp.hover,
          active: lightHelp.active,
        },
        background: {
          default: lightHelp.backgroundDefault,
          hover: lightHelp.backgroundHover,
          active: lightHelp.backgroundActive,
        },
        foreground: {
          default: lightHelp.foreground,
          hover: lightHelp.foreground,
          active: lightHelp.foreground,
        },
      },
    },
    dark: {
      foreground: {
        default: darkForeground.default,
        medium: darkForeground.medium,
        light: darkForeground.light,
        disabled: darkForeground.disabled,
      },
      background: {
        default: darkBackground.default,
        medium: darkBackground.medium,
        disabled: darkBackground.disabled,
        container: {
          default: darkBackground.container,
          hover: darkBackground.containerHover,
          active: darkBackground.containerActive,
        },
        containerHover: darkBackground.containerHover,
        containerActive: darkBackground.containerActive,
        stroke: darkBackground.stroke,
      },
      primary: {
        base: {
          default: darkPrimary.default,
          hover: darkPrimary.hover,
          active: darkPrimary.active,
        },
        background: {
          default: darkPrimary.backgroundDefault,
          hover: darkPrimary.backgroundHover,
          active: darkPrimary.backgroundActive,
        },
        foreground: {
          default: darkPrimary.foreground,
          hover: darkPrimary.foreground,
          active: darkPrimary.foreground,
        },
      },
      neutral: {
        base: {
          default: darkNeutral.default,
          hover: darkNeutral.hover,
          active: darkNeutral.active,
        },
        background: {
          default: darkNeutral.backgroundDefault,
          hover: darkNeutral.backgroundHover,
          active: darkNeutral.backgroundActive,
        },
        foreground: {
          default: darkNeutral.foreground,
          hover: darkNeutral.foreground,
          active: darkNeutral.foreground,
        },
      },
      stroke: { default: darkBackground.stroke },
      positive: {
        base: {
          default: darkPositive.default,
          hover: darkPositive.hover,
          active: darkPositive.active,
        },
        background: {
          default: darkPositive.backgroundDefault,
          hover: darkPositive.backgroundHover,
          active: darkPositive.backgroundActive,
        },
        foreground: {
          default: darkPositive.foreground,
          hover: darkPositive.foreground,
          active: darkPositive.foreground,
        },
      },
      caution: {
        base: {
          default: darkCaution.default,
          hover: darkCaution.hover,
          active: darkCaution.active,
        },
        background: {
          default: darkCaution.backgroundDefault,
          hover: darkCaution.backgroundHover,
          active: darkCaution.backgroundActive,
        },
        foreground: {
          default: darkCaution.foreground,
          hover: darkCaution.foreground,
          active: darkCaution.foreground,
        },
      },
      warning: {
        base: {
          default: darkWarning.default,
          hover: darkWarning.hover,
          active: darkWarning.active,
        },
        background: {
          default: darkWarning.backgroundDefault,
          hover: darkWarning.backgroundHover,
          active: darkWarning.backgroundActive,
        },
        foreground: {
          default: darkWarning.foreground,
          hover: darkWarning.foreground,
          active: darkWarning.foreground,
        },
      },
      negative: {
        base: {
          default: darkNegative.default,
          hover: darkNegative.hover,
          active: darkNegative.active,
        },
        background: {
          default: darkNegative.backgroundDefault,
          hover: darkNegative.backgroundHover,
          active: darkNegative.backgroundActive,
        },
        foreground: {
          default: darkNegative.foreground,
          hover: darkNegative.foreground,
          active: darkNegative.foreground,
        },
      },
      help: {
        base: {
          default: darkHelp.default,
          hover: darkHelp.hover,
          active: darkHelp.active,
        },
        background: {
          default: darkHelp.backgroundDefault,
          hover: darkHelp.backgroundHover,
          active: darkHelp.backgroundActive,
        },
        foreground: {
          default: darkHelp.foreground,
          hover: darkHelp.foreground,
          active: darkHelp.foreground,
        },
      },
    },
  },
});

export default wexTheme;
