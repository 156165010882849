import { COMPONENT_NAMES } from "./constants";

export const ORG_ROLES = {
  ORG_ADMIN: "Organization Administrator",
  ORG_USER: "Organization User",
  TENANT_CREATOR: "Tenant Creator",
  TENANT_ADMIN: "Tenant Administrator",
};

export const DEFAULT_PERMISSIONS = {
  [ORG_ROLES.ORG_ADMIN]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  [ORG_ROLES.ORG_USER]: [],
  [ORG_ROLES.TENANT_CREATOR]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  [ORG_ROLES.TENANT_ADMIN]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
};

export const hasLimitedTenantAccess = (role) => {
  return role === ORG_ROLES.ORG_USER;
};
