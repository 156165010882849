import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import {
  filterCheckpointsData,
  queryString,
  transformLogsData,
} from "../../common/utils";

export const getOrganizationLogs = createAsyncThunk(
  "getOrganizationLogs",
  async (
    {
      useCache = true,
      organizationId,
      timespan,
      tenant,
      offset,
      search,
      store,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingLogsRequestId, loadingLogs, organizationLogs, meta } =
      getState().dashboard.logs;

    if (!loadingLogs || loadingLogsRequestId !== requestId) {
      return;
    }
    if (useCache && organizationLogs[organizationId]) {
      return {
        data: organizationLogs[organizationId],
        organizationId,
        cachedData: true,
      };
    }
    const nextPage = (meta?.[organizationId]?.CurrentPage || 0) + 1;
    let params = { duration: timespan };
    if (store) {
      params = { ...params, pageSize: offset, pageNumber: nextPage };
      let queryStr = `requests | where name !contains "GET" | where timestamp > ago(${timespan})`;
      if (tenant && tenant !== "-1") {
        params = { ...params, tenantId: tenant };
      }
      if (search) {
        queryStr += `| search "*${search}*" `;
        params = { ...params, query: queryStr };
      } else {
        params = { ...params, query: queryStr };
      }
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/reporting/aiLogs?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUserLogonLogs = createAsyncThunk(
  "getUserLogonLogs",
  async (
    {
      useCache = true,
      tenantId,
      organizationId,
      activeTimeDiff,
      timespan,
      offset,
      search,
      username,
      vmName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingLogsRequestId, loadingLogs, userLogonLogs } =
      getState().dashboard.logs;

    if (!loadingLogs || loadingLogsRequestId !== requestId) {
      return;
    }
    if (useCache && userLogonLogs[tenantId]) {
      return { data: userLogonLogs[tenantId], tenantId, cachedData: true };
    }
    let query = "WVDConnections ";
    if (search?.trim()) {
      query += `|search "*${search}*"`;
    }
    if (username) {
      query += `|where UserName == "${username}" `;
    }
    query += '|where State == "Started" ';
    if (vmName) {
      query += `|where SessionHostName contains "${vmName}" `;
    }
    if (activeTimeDiff) {
      query += `|where ${"TimeGenerated"} <= ago(${activeTimeDiff})`;
    } else if (timespan) {
      query += `|where TimeGenerated >= ago(${timespan}) `;
    }

    query += "|extend InitiatedTime=TimeGenerated";
    query += "|join kind= leftouter (WVDConnections ";
    query += '|where State == "Connected" ';
    query += "|project  StartTime=TimeGenerated, CorrelationId)";
    query += "on CorrelationId ";
    query += "|join kind= leftouter (WVDConnections ";
    query += '|where State == "Completed"';
    query += "| project EndTime=TimeGenerated, CorrelationId) ";
    query += " on CorrelationId ";
    query += " |join (WVDCheckpoints ";
    query += '|where Name == "LoadBalancedNewConnection" ';
    query +=
      " |project SessionTarget=parse_json(Parameters).LoadBalanceOutcome, CorrelationId)";
    query += " on CorrelationId ";
    query += "|join kind= leftouter (WVDCheckpoints";
    query += '| where Name == "RdpStackDisconnect"';
    query +=
      "| project ResultCode=parse_json(Parameters).codeSymbolic, CorrelationId)";
    query += "on CorrelationId ";
    query +=
      '|join (WVDCheckpoints | extend d = pack("Name", Name, "Source", Source, "TimeGenerated", TimeGenerated )';
    query +=
      "|summarize Checkpoints=make_list(d), opid=count() by CorrelationId";
    query += "|project Checkpoints, CorrelationId ) on CorrelationId";
    query += "|extend  Duration = EndTime - StartTime";
    query += "|project-away State, TimeGenerated";
    if (offset) {
      query += `|top ${offset} by "InitiatedTime" desc`;
    } else {
      query += '|sort by "InitiatedTime" desc';
    }
    const params = queryString({ query, timespan });
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/reporting/logAnalytics?${params}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenantLogs = createAsyncThunk(
  "getTenantLogs",
  async (
    { useCache = true, tenantId, organizationId, timespan, search, store },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingLogsRequestId, loadingLogs, tenantLogs } =
      getState().dashboard.logs;

    if (!loadingLogs || loadingLogsRequestId !== requestId) {
      return;
    }
    if (useCache && tenantLogs[tenantId]) {
      return { data: tenantLogs[tenantId], tenantId, cachedData: true };
    }

    let params = { duration: timespan };
    let queryStr = `requests | where name !contains "GET" | where timestamp > ago(${timespan})`;
    if (store) {
      params = { ...params, tenantId };
      if (search) {
        params = { ...params, search: `search "*${search}*"`, query: queryStr };
      } else {
        params = { ...params, query: queryStr };
      }
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/reporting/aiLogs?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOrchestrationLogs = createAsyncThunk(
  "getOrchestrationLogs",
  async (
    {
      useCache = true,
      tenantId,
      organizationId,
      timespan,
      severity,
      offset,
      search,
      sortBy,
      store,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingLogsRequestId, loadingLogs, orchestrationLogs, meta } =
      getState().dashboard.logs;

    if (!loadingLogs || loadingLogsRequestId !== requestId) {
      return;
    }
    if (useCache && orchestrationLogs[tenantId]) {
      return { data: orchestrationLogs[tenantId], tenantId, cachedData: true };
    }
    const nextPage = (meta?.[tenantId]?.CurrentPage || 0) + 1;
    let params = { duration: timespan };
    if (store) {
      params = {
        ...params,
        pageSize: offset,
        pageNumber: nextPage,
        severity,
        sortBy,
      };
      if (search) {
        params = { ...params, search: search };
      } else {
        params = { ...params };
      }
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/reporting/orchestrationLogs?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSessionHistoryLogs = createAsyncThunk(
  "getSessionHistoryLogs",
  async (
    {
      useCache = true,
      tenantId,
      organizationId,
      offset,
      search,
      orderBy,
      store,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingLogsRequestId, loadingLogs, sessionHistoryLogs, meta } =
      getState().dashboard.logs;

    if (!loadingLogs || loadingLogsRequestId !== requestId) {
      return;
    }
    if (useCache && sessionHistoryLogs[tenantId]) {
      return { data: sessionHistoryLogs[tenantId], tenantId, cachedData: true };
    }
    const nextPage = (meta?.[tenantId]?.CurrentPage || 0) + 1;
    let params = {};
    if (store) {
      params = {
        ...params,
        pageSize: offset,
        pageNumber: nextPage,
        orderBy,
      };
      if (search) {
        params = { ...params, search: search };
      } else {
        params = { ...params };
      }
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/users/pcoipSessionHistory?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getLogsQueries = createAsyncThunk(
  "getLogsQueries",
  async (
    { useCache = true, tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingQueriesRequestId, loadingQueries, queries } =
      getState().dashboard.logs;

    if (!loadingQueries || loadingQueriesRequestId !== requestId) {
      return;
    }
    if (useCache && queries[tenantId]) {
      return { data: queries[tenantId], tenantId, cachedData: true };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/reporting/savedQueries`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveLogsQuery = createAsyncThunk(
  "saveLogsQuery",
  async (
    { tenantId, organizationId, query },
    { getState, requestId, rejectWithValue },
  ) => {
    const { savingQueriesRequestId, savingQueries } = getState().dashboard.logs;
    if (typeof query !== "string") {
      return rejectWithValue("Invalid query");
    }
    if (!savingQueries || savingQueriesRequestId !== requestId) {
      return;
    }

    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/reporting/query/${query}`,
        method: HTTP_METHODS.POST,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  logs: {},
  organizationLogs: {},
  tenantLogs: {},
  orchestrationLogs: {},
  sessionHistoryLogs: {},
  userLogonLogs: {},
  loadingLogs: false,
  loadingLogsRequestId: null,
  error: null,
  queries: {},
  loadingQueries: false,
  loadingQueriesRequestId: null,
  savingQueries: false,
  savingQueryError: null,
  meta: {},
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = null;
      state.savingQueryError = null;
    },
    resetLogs: (state) => {
      state.userLogonLogs = {};
    },
  },
  extraReducers: {
    [getOrganizationLogs.pending]: (state, action) => {
      state.loadingLogs = true;
      state.loadingLogsRequestId = action.meta.requestId;
      state.error = null;
      if (!action.meta.arg?.currentLogsCount) {
        state.meta[action.meta.arg.organizationId] = {};
      }
    },
    [getOrganizationLogs.fulfilled]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.loadingLogs = false;
      if (action.payload.data?.message) {
        state.error = action.payload.data;
      } else if (action.meta.arg.store) {
        if (action.payload?.organizationId) {
          let data = action.payload.data;
          if (!action.payload?.cachedData) {
            data = transformLogsData(
              action.payload.data,
              action.meta?.arg?.columnsOrder,
            );
          }
          _.set(
            state.organizationLogs,
            `${action.payload.organizationId}`,
            data,
          );
          _.set(
            state.meta,
            `${action.payload.organizationId}`,
            action.payload?.meta,
          );
        }
      }
    },
    [getOrganizationLogs.rejected]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.error = action.payload || true;
      state.loadingLogs = false;
    },
    [getUserLogonLogs.pending]: (state, action) => {
      state.loadingLogs = true;
      state.loadingLogsRequestId = action.meta.requestId;
      state.error = null;
      if (!action.meta.arg?.currentLogsCount) {
        state.meta[action.meta.arg.tenantId] = {};
      }
    },
    [getUserLogonLogs.fulfilled]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.loadingLogs = false;
      if (action.payload.data?.message !== "Success") {
        state.error = action.payload.data;
      } else if (action.meta.arg.store) {
        if (action.payload?.tenantId) {
          let data = action.payload.data;
          if (!action.payload?.cachedData) {
            data = transformLogsData(
              action.payload.data,
              action.meta?.arg?.columnsOrder,
              "Checkpoints",
              true,
            );
            data = filterCheckpointsData(data);
          }
          _.set(state.userLogonLogs, `${action.payload.tenantId}`, data);
        }
      }
    },
    [getUserLogonLogs.rejected]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.error = action.payload || true;
      state.loadingLogs = false;
    },
    [getTenantLogs.pending]: (state, action) => {
      state.loadingLogs = true;
      state.loadingLogsRequestId = action.meta.requestId;
      state.error = null;
      if (!action.meta.arg?.currentLogsCount) {
        state.meta[action.meta.arg.tenantId] = {};
      }
    },
    [getTenantLogs.fulfilled]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.loadingLogs = false;
      if (action.payload.data?.message) {
        state.error = action.payload.data;
      } else if (action.meta.arg.store) {
        if (action.payload?.tenantId) {
          let data = action.payload.data;
          if (!action.payload?.cachedData) {
            data = transformLogsData(
              action.payload.data,
              action.meta?.arg?.columnsOrder,
            );
          }
          _.set(state.tenantLogs, `${action.payload.tenantId}`, data);
          _.set(state.meta, `${action.payload.tenantId}`, action.payload?.meta);
        }
      }
    },
    [getTenantLogs.rejected]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.error = action.payload || true;
      state.loadingLogs = false;
    },
    [getLogsQueries.pending]: (state, action) => {
      state.loadingQueries = true;
      state.loadingQueriesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getLogsQueries.fulfilled]: (state, action) => {
      state.loadingQueriesRequestId = null;
      state.loadingQueries = false;
      _.set(
        state.queries,
        `${action.payload.tenantId}`,
        action.payload?.data?.value || action.payload?.data,
      );
    },
    [getLogsQueries.rejected]: (state) => {
      state.loadingQueriesRequestId = null;
      state.error = { message: "Error getting saved queries" };
      state.loadingQueries = false;
    },
    [saveLogsQuery.pending]: (state, action) => {
      state.savingQueries = true;
      state.savingQueriesRequestId = action.meta.requestId;
      state.savingQueryError = null;
    },
    [saveLogsQuery.fulfilled]: (state, action) => {
      state.savingQueriesRequestId = null;
      state.savingQueries = false;
      let queries = state.queries?.[action.payload.tenantId];
      if (action.payload?.data?.properties) {
        if (queries && queries?.length > 0) {
          _.set(state.queries, `${action.payload.tenantId}`, [
            ...queries,
            action.payload?.data,
          ]);
        } else {
          _.set(state.queries, `${action.payload.tenantId}`, [
            action.payload?.data,
          ]);
        }
        state.savingQueryError = false;
      } else {
        state.savingQueryError = action.payload?.data;
      }
    },
    [saveLogsQuery.rejected]: (state) => {
      state.savingQueriesRequestId = null;
      state.savingQueryError = { message: "Error saving query" };
      state.savingQueries = false;
    },
    [getOrchestrationLogs.pending]: (state, action) => {
      state.loadingLogs = true;
      state.loadingLogsRequestId = action.meta.requestId;
      state.error = null;
      state.meta = {};
    },
    [getOrchestrationLogs.fulfilled]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.loadingLogs = false;
      if (action.payload.data?.message !== "Success") {
        state.error = action.payload.data;
      } else if (action.meta.arg.store) {
        if (action.payload?.tenantId) {
          let data = action.payload.data;
          if (!action.payload?.cachedData) {
            data = transformLogsData(
              action.payload.data,
              action.meta?.arg?.columnsOrder,
            );
          }
          _.set(state.orchestrationLogs, `${action.payload.tenantId}`, data);
        }
      }
    },
    [getOrchestrationLogs.rejected]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.error = action.payload || true;
      state.loadingLogs = false;
    },
    [getSessionHistoryLogs.pending]: (state, action) => {
      state.loadingLogs = true;
      state.loadingLogsRequestId = action.meta.requestId;
      state.error = null;
      state.meta = {};
    },
    [getSessionHistoryLogs.fulfilled]: (state, action) => {
      const formatRows = (payload) => {
        return payload.map((item) => {
          return {
            machineName: item.machineName,
            loggedInUsers: item.loggedInUsers[0],
            // connectionTypeNA: item.connectionTypeNA,
            // initialGatewayNA: item.initialGatewayNA,
            approximateDurationMs: item.approximateDurationMs,
            startedOn: item.startedOn,
            lastUpdatedOn: item.lastUpdatedOn,
            sessionState: item.sessionState,
          };
        });
      };

      state.loadingLogsRequestId = null;
      state.loadingLogs = false;
      const data = formatRows(action.payload?.data);
      _.set(state.sessionHistoryLogs, `${action.payload.tenantId}`, data);
    },
    [getSessionHistoryLogs.rejected]: (state, action) => {
      state.loadingLogsRequestId = null;
      state.error = action.payload || true;
      state.loadingLogs = false;
    },
  },
});

export const { resetErrors, resetLogs } = logsSlice.actions;
export default logsSlice.reducer;
