/* eslint-disable camelcase */
import moment from "moment";
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NavLink } from "react-router-dom";
import SeverityIndicator from "../common/components/SeverityIndicator";
import {
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../common/constants";
import {
  FINALIZING_STATUS,
  INITIALIZING_STATUS,
} from "../onboarding/onboardingConstants";
import { AlertsTag } from "./components/tags/AlertsTag";
import { getResourceDetailsFromResourceList } from "./dashboardUtils";

export const SERVER_TYPE_PLATFORM = "Infrastructure";

export const ORG_ADMIN_ROLE = "Organization Administrator";

export const CPU_USAGE_WARNING_THRESHOLD = 75;
export const CPU_USAGE_CRITICAL_THRESHOLD = 90;

export const LOG_ANALYTICS_RESOURCE_TYPE = "LogAnalytics";

export const MIN_ACTIVE_IDLE_TIMEOUT = 1;
export const MIN_DISCONNECT_IDLE_TIMEOUT = 1;

export const MAX_ACTIVE_IDLE_TIMEOUT = 240;
export const MAX_DISCONNECT_IDLE_TIMEOUT = 240;

export const CPU_PEAK_USAGE_WARNING_THRESHOLD = 75;
export const CPU_PEAK_USAGE_CRITICAL_THRESHOLD = 90;

export const PQL_WARNING_THRESHOLD = 4;
export const PQL_CRITICAL_THRESHOLD = 6;

export const ACTIVE_USERS_WARNING_THRESHOLD = 10;
export const ACTIVE_USERS_CRITICAL_THRESHOLD = 14;

export const INACTIVE_USERS_CRITICAL_THRESHOLD = 1;

export const RAM_USAGE_WARNING_THRESHOLD = 75;
export const RAM_USAGE_CRITICAL_THRESHOLD = 90;

export const RAM_PEAK_USAGE_WARNING_THRESHOLD = 75;
export const RAM_PEAK_USAGE_CRITICAL_THRESHOLD = 90;

export const USER_INPUT_DELAY_WARNING_THRESHOLD = 150;
export const USER_INPUT_DELAY_CRITICAL_THRESHOLD = 200;
export const ROUND_TRIP_TIME_WARNING_THRESHOLD = 150;
export const ROUND_TRIP_TIME_CRITICAL_THRESHOLD = 200;

// Dashboard overview tab components visiblility
export const SHOW_SPOT_PC_USAGE = false;
export const SHOW_DATA_VOLUME = false;
export const SHOW_AVERAGE_USERS = false;
export const SHOW_SPOT_PC_AUTOMATED_SERVICES = false;

//Dashboard machines tab components visibility
export const SHOW_MACHINE_DETAILS_LATENCY = false;

export const REL_ANN_MAX_CHAR = 300;

export const LOGOUT_INTERVALS = [
  {
    value: 15,
    name: "15 Minutes",
  },
  {
    value: 60,
    name: "1 Hour",
  },
  {
    value: 120,
    name: "2 Hours",
  },
  {
    value: 180,
    name: "3 Hours",
  },
  {
    value: 240,
    name: "4 Hours",
  },
  {
    value: 300,
    name: "5 Hours",
  },
  {
    value: 360,
    name: "6 Hours",
  },
];

export const SECURITY_TABS = {
  ALERTS: "alerts",
  RECOMMENDATIONS: "recommendations",
};

export const LOGS_TABS = {
  TENANT_LOGS: "tenantLogs",
  ACTIVITY_LOGS: "activityLogs",
};

export const COST_SAVING_TABS = [
  { label: "Month to Date", value: "m2d" },
  { label: "Last Month", value: "lastMonth" },
  { label: "7 Days", value: "7d" },
  { label: "30 Days", value: "30d" },
  { label: "90 Days", value: "90d" },
];

export const SPOT_PC_USAGE_TABS = [
  { label: "1 Week", value: "1W" },
  { label: "1 Month", value: "1M" },
  { label: "3 Months", value: "3M" },
];

export const SPOT_PC_MACHINE_TABS = [
  { label: "15 Min", value: "15M" },
  { label: "3 Hours", value: "3H" },
  { label: "24 Hours", value: "24H" },
  { label: "1 Week", value: "1W" },
];

export const SPOT_PC_AUTOMATED_SERVICES_TABS = [
  { label: "1 Day", value: "1D" },
  { label: "1 Week", value: "1W" },
];

export const AVERAGE_USERS_TABS = [
  { label: "1 Week", value: "1W" },
  { label: "1 Month", value: "1M" },
  { label: "3 Months", value: "3M" },
];

export const RESOURCE_HEALTH_STATUS = {
  HEALTHY: "healthy",
  UNHEALTHY: "unhealthy",
  NOT_APPLICABLE: "notapplicable",
};

export const AFFECTED_RESOURCES_TABS = [
  { label: "Unhealthy Resources", value: RESOURCE_HEALTH_STATUS.UNHEALTHY },
  { label: "Healthy resouces", value: RESOURCE_HEALTH_STATUS.HEALTHY },
  {
    label: "Not applicable resources",
    value: RESOURCE_HEALTH_STATUS.NOT_APPLICABLE,
  },
];

export const RESOURCE_DETAILS_HEADINGS = {
  subscriptions: "Subscription",
  resourcegroups: "Resource Group",
  providers: "Provider",
  virtualmachines: "Virtual Machine",
};

export const AFFECTED_RESOURCES_COLUMNS = [
  {
    header: "Name",
    accessor: "resourceName",
    id: "name",
    identityColumn: true,
  },
  {
    header: "Resource Id",
    accessor: "resourceId",
    id: "resourceId",
  },
  {
    header: "Subscription",
    accessor: "subscriptionId",
    id: "subscription",
  },
];

export const ACCOUNT_TAB_COLUMNS = [
  {
    header: "Name",
    accessor: "name",
    id: "name",
    identityColumn: true,
  },
  {
    header: "UPN suffix",
    accessor: "upnSuffix",
    id: "upnSuffix",
  },
  {
    header: "AAD user ID",
    accessor: "aadUserId",
    id: "aadUserId",
  },
  {
    header: "Host",
    accessor: "host.hostName",
    id: "host",
  },
];

export const FILE_TAB_COLUMNS = [
  {
    header: "Name",
    accessor: "name",
    id: "name",
    identityColumn: true,
  },
  {
    header: "Directory",
    accessor: "directory",
    id: "directory",
  },
  {
    header: "Host",
    accessor: "host",
    id: "host",
  },
  {
    header: "File Hashes",
    accessor: "fileHashes",
    id: "fileHashes",
  },
  {
    header: "Threat Intelligence",
    accessor: "threatIntelligence",
    id: "threatIntelligence",
  },
];

export const HOST_TAB_COLUMNS = [
  {
    header: "Name",
    accessor: "hostName",
    id: "hostName",
    identityColumn: true,
  },
  {
    header: "Azure ID",
    accessor: "azureID",
    id: "azureID",
  },
  {
    header: "OS family",
    accessor: "osFamily",
    id: "osFamily",
  },
  {
    header: "DNS domain",
    accessor: "dnsDomain",
    id: "dnsDomain",
  },
];

export const PROCESSES_TAB_COLUMNS = [
  {
    header: "Process ID",
    accessor: "processId",
    id: "processId",
  },
  {
    header: "Command line",
    accessor: "commandLine",
    id: "commandLine",
  },
  {
    header: "Creation time",
    accessor: "creationTime",
    id: "creationTime",
  },
  {
    header: "Host",
    accessor: "host.hostName",
    id: "host",
  },
  {
    header: "Parent Process",
    accessor: "parentProcess.processId",
    id: "parentProcess",
  },
  {
    header: "Account",
    accessor: "account.name",
    id: "account",
  },
  {
    header: "File",
    accessor: "imageFile.name",
    id: "file",
  },
  {
    header: "Logon session",
    accessor: "logonSession",
    id: "logonSession",
  },
];

export const TOTAL_COST_OVER_TIME = [
  { label: "1 Week", value: "1W" },
  { label: "1 Month", value: "1M" },
  { label: "3 Months", value: "3M" },
];

export const MACHINE_SOCKET_METRICS = {
  cpu: {
    category: "spotpc",
    measurement: "agent",
    metric: "win_cpu.percent_processor_time",
    title: "CPU Consumption %",
    filter_by: "agent_node_name",
    groupBy: "agent_node_name",
    transform: "average-peak",
    prefix: "cpu_",
  },
  memory: {
    category: "spotpc",
    measurement: "agent",
    metric: "(100-win_mem.available_percent)",
    filter_by: "agent_node_name",
    title: "Memory Consumption %",
    groupBy: "agent_node_name",
    transform: "average-peak",
    prefix: "memory_",
  },
  cpu_queue: {
    category: "spotpc",
    measurement: "agent",
    filter_by: "agent_node_name",
    metric: "win_system.processor_queue_length",
    title: "Processor Queue Length",
    groupBy: "agent_node_name",
    transform: "average-peak",
    prefix: "processor_queue_length_",
  },
  active_user_session: {
    category: "spotpc",
    measurement: "agent",
    filter_by: "agent_node_name",
    metric: "win_rds.active_sessions",
    title: "Processor Queue Length",
    groupBy: "agent_node_name",
    transform: "average-peak",
    prefix: "active_session_",
  },
  inactive_user_session: {
    category: "spotpc",
    measurement: "agent",
    filter_by: "agent_node_name",
    metric: "(win_rds.inactive_sessions-2)",
    title: "Processor Queue Length",
    groupBy: "agent_node_name",
    transform: "average-peak",
    prefix: "inactive_session_",
  },
};

export const ONBOARDING_ROUTE = "/onboarding";
export const CREATE_TENANT_ROUTE = "/onboardingVSphere";
export const SELECT_TENANT_TYPE = "/selectService";
export const SELECT_SERVICE_SITE_TYPE = "/sites/selectService";

export const MACHINES_APPS_SOCKET_METRICS = {
  cpu: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_mem.commitsizeperprocess",
    title: "Memory consumption %",
    filter_by: "agent_node_name",
    groupBy: "instance,pid,user",
    transform: "average-peak",
    prefix: "memory_",
  },
  memory: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_cpu.cpuperproceess",
    filter_by: "agent_node_name",
    title: "Cpu consumption %",
    groupBy: "instance,pid,user",
    transform: "average-peak",
    prefix: "cpu_",
  },
};

export const MACHINE_CAPACITY_SOCKET_METRICS = {
  capacityFreeSpace: {
    category: "spotpc",
    measurement: "agent",
    metric: "win_disk.free_megabytes",
    title: "Memory consumption %",
    filter_by: "agent_node_name",
  },
  percentFreeSpace: {
    category: "spotpc",
    measurement: "agent",
    metric: "win_disk.percent_free_space",
    title: "Free Space %",
    filter_by: "agent_node_name",
  },
};

export const USER_SESSION_APPS_SOCKET_METRICS = {
  cpu: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_mem.commitsizeperprocess",
    title: "Memory consumption %",
    filter_by: "user",
    groupBy: "instance,pid",
    transform: "average-peak",
    prefix: "memory_",
  },
  memory: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_cpu.cpuperproceess",
    filter_by: "user",
    title: "Cpu consumption %",
    groupBy: "instance,pid",
    transform: "average-peak",
    prefix: "cpu_",
  },
};

export const USER_SESSIONS_SOCKET_METRICS = {
  cpu: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_cpu.cpuperuser",
    title: "CPU Consumption %",
    filter_by: "",
    groupBy: "agent_node_name,user",
    transform: "average-peak",
    prefix: "cpu_",
  },
  memory: {
    category: "spotpc",
    measurement: "agent",
    metric: "user_mem.commitsizeperuser",
    filter_by: "",
    title: "Memory Consumption %",
    groupBy: "agent_node_name,user",
    transform: "average-peak",
    prefix: "memory_",
  },
  user_input_delay: {
    category: "spotpc.agent",
    measurement: "win_rds",
    metric: "max_input_delay",
    filter_by: "",
    title: "User Input Delay ms",
    groupBy: "agent_node_name,user",
    transform: "average-peak",
    prefix: "user_input_delay_",
  },
  round_trip_time: {
    category: "spotpc.agent",
    measurement: "win_rds",
    metric: "current_tcp_rtt",
    filter_by: "",
    title: "Round Trip Time ms",
    groupBy: "agent_node_name,user",
    transform: "average-peak",
    prefix: "round_trip_time_",
  },
  session_idle_time: {
    category: "spotpc.agent",
    measurement: "duration",
    metric: "idleordisconecttimeinsec",
    filter_by: "",
    title: "System idle time sec",
    groupBy: "agent_node_name,user",
    transform: "average-peak",
    prefix: "session_idle_time_",
  },
};

export const RECOMMENDATIONS_COLUMNS = [
  {
    header: "Name",
    identityColumn: true,
    accessor: "displayName",
    id: "name",
    cell: (row) => (
      <NavLink
        to={`/dashboard/security/recommendations/${row.assessmentKey}`}
        exact
      >
        {row.value}
      </NavLink>
    ),
  },
  {
    header: "Severity",
    accessor: "severity",
    id: "severity",
  },
  {
    header: "Completion Status",
    accessor: "completionStatus",
    id: "completionStatus",
  },
  {
    header: "Resource Count",
    accessor: "resourceCount",
    id: "resourceCount",
  },
  {
    header: "Resource Health",
    accessor: "exposedMachinesCount",
    id: "exposedMachinesCount",
    customStyles: () => {
      return {
        width: "150px",
      };
    },
    cell: (row) => {
      const total = row.resourceCount;
      const unhealthy =
        row?.statusAndCount?.find((s) => s.statusCode === "Unhealthy")
          ?.resourceCount || 0;
      const healthy = total - unhealthy;

      return (
        <ProgressBar>
          <ProgressBar
            variant="danger-color"
            title={unhealthy + " of " + total + " are unhealty"}
            now={(unhealthy / total) * 100}
            key={1}
          />
          <ProgressBar
            variant="success-color"
            title={healthy + " of " + total + " are healthy"}
            now={(healthy / total) * 100}
            key={2}
          />
        </ProgressBar>
      );
    },
  },
];

export const ALERTS_COLUMNS = [
  {
    header: "Description",
    accessor: "properties.alertDisplayName",
    identityColumn: true,
    id: "alert",
    cell: (row) => (
      <NavLink
        to={`/dashboard/security/alerts/${row.properties?.systemAlertId}`}
        exact
      >
        {row.value}
      </NavLink>
    ),
  },
  {
    header: "Severity",
    accessor: "properties.severity",
    id: "severity",
    cell: (row) => {
      return <SeverityIndicator severityLevel={row.value} />;
    },
  },
  {
    header: "Affected Resource",
    accessor: "properties.resourceIdentifiers",
    id: "affectedResource",
    cell: (row) => {
      const resourceList =
        row.value !== "--"
          ? row.value?.filter(
              (resource) => resource.type !== LOG_ANALYTICS_RESOURCE_TYPE,
            )
          : [];
      if (resourceList && resourceList.length > 0) {
        const resourceDetails = getResourceDetailsFromResourceList(row.value);
        return (
          <span>
            {resourceDetails["Virtual Machine"]
              ? resourceDetails["Virtual Machine"]
              : "N/A"}
          </span>
        );
      } else {
        return <span>--</span>;
      }
    },
  },
  {
    header: "Status",
    accessor: "properties.status",
    id: "status",
    cell: (row) => {
      return <AlertsTag status={row.value} />;
    },
  },
  {
    header: "Date Generated",
    accessor: "properties.timeGeneratedUtc",
    id: "dateGenerated",
    cell: (row) => {
      const date = moment.utc(row.value).local().format("DD MMM YYYY");
      return <span>{date}</span>;
    },
  },
];

export const TENANT_NOT_ACTIVE_STATUSES = [
  INITIALIZING_STATUS,
  FINALIZING_STATUS,
];

export const EXTRACT_EMAIL_REGEX =
  /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._+-]+\.[a-zA-Z0-9_+-]+)/gi;

export const ORGANIZATION_TIMESPAN_OPTIONS = [
  { name: "Last 15 Minutes", value: "15m" },
  { name: "Last 30 Minutes", value: "30m" },
  { name: "Last 60 Minutes", value: "60m" },
  { name: "Last 2 Hours", value: "2h" },
  { name: "Last 3 Hours", value: "3h" },
  { name: "Last 6 Hours", value: "6h" },
  { name: "Last 12 Hours", value: "12h" },
  { name: "Last 24 Hours", value: "1d" },
  { name: "Past 48 Hours", value: "2d" },
  { name: "Past 1 Week", value: "7d" },
  { name: "Past 30 Days", value: "30d" },
];

export const USERLOGON_TIMESPAN_OPTIONS = [
  { name: "Last 15 Minutes", value: "15m" },
  { name: "Last 30 Minutes", value: "30m" },
  { name: "Last 60 Minutes", value: "60m" },
  { name: "Last 2 Hours", value: "2h" },
  { name: "Last 3 Hours", value: "3h" },
  { name: "Last 6 Hours", value: "6h" },
  { name: "Last 12 Hours", value: "12h" },
  { name: "Last 24 Hours", value: "1d" },
  { name: "Past 48 Hours", value: "2d" },
  { name: "Past 1 Week", value: "7d" },
  { name: "Past 30 Days", value: "30d" },
  { name: "Past 60 Days", value: "60d" },
  { name: "Past 90 Days", value: "90d" },
];

export const ADMIN_TABLE_ACCORDIAN = "AdminTableAccordian";
export const ADMIN_ACTIVITY_LOGS_ORDER = [
  "UserName",
  "url",
  "name",
  "timestamp",
  "resultCode",
  "success",
  "duration",
];

export const ADMIN_ACTIVITY_LOGS_HEADER = {
  UserName: "Username",
  url: "Url",
  name: "Action",
  timestamp: "Timestamp",
  resultCode: "ResultCode",
  success: "Success",
  duration: "Duration(ms)",
};

export const ADMIN_CONFIG_ACTIONS_PERMISSIONS_SET = [
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.DELETE],
];

export const CONFIG_ACTIONS_PERMISSIONS_SET = [
  PERMISSIONS_LIST[MODULES_LIST.IMAGES][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.IMAGES][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.IMAGES][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.IMAGES][PERMISSIONS_TYPE.ACCESS],
  PERMISSIONS_LIST[MODULES_LIST.IMAGES][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.SPOT_GROUPS][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.CONNECTIONS][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.DEVICE_IMAGES][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.PROVISIONING_POLICY][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.BUSINESS_SERVERS][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][PERMISSIONS_TYPE.DELETE],
  PERMISSIONS_LIST[MODULES_LIST.ADMIN_ACCOUNTS][PERMISSIONS_TYPE.VIEW],
];

export const TYPE_ANF = "ANF";
