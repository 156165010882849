export const getDirectoryServiceLabel = (joinToDomain) => {
  switch (joinToDomain) {
    case "AD":
      return "Active Directory hybrid";
    default:
      return "Entra only";
  }
};

export const getHypervisorDisplayName = (hypervisorType) => {
  const hypervisorLabels = {
    cloudpc: "Windows 365",
    vcenter: "VMware",
    unmanagedhypervisor: "Registered devices",
  };

  const type = hypervisorType?.toLowerCase();

  return hypervisorLabels[type] || "--";
};
