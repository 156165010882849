import RefreshIcon from "@mui/icons-material/Refresh";
import _ from "lodash";
import React from "react";
import Back from "../common/components/Back";
import DashBoardRouteTitle from "../common/components/DashboardRouteTitle";
import EntityActionDropdown from "../common/components/EntityActionDropdown";
import ParentRoute from "../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../common/components/ProtectedRoute";
import {
  APPLICATION_CONTEXTS,
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
  SNACKBAR_DURATION_MS,
  SUPPORT_ADMIN,
  SUPPORT_ROLES,
} from "../common/constants";
import { ORG_ROLES } from "../common/roles.js";
import { getIdentityId, getTenantId } from "../common/utils";
import MachinesDetailsWrapper from "../dashboard/components/machines/MachinesDetailsWrapper.jsx";
import DisableLogin from "../icons/DisableLogin.svg";
import EnableLogin from "../icons/EnableLogin.svg";
import ConnectedIcon from "../icons/connectedIcon.svg";
import MonitorIcon from "../icons/monitorIcon.svg";
import {
  SuspenseLoader,
  componentLoader,
} from "./../common/components/SuspenseComponent";
import MachinesWrapper from "./components/machines/MachinesWrapper";
import EditTenant from "./components/tenants/EditTenant.jsx";
import UserSessionDetailsWrapper from "./components/userSessions/UserSessionDetailsWrapper";
import UserSessionWrapper from "./components/userSessions/UserSessionWrapper";
import { SERVER_TYPE_PLATFORM } from "./dashboardConstants";
import { getSiteName, getSpotGroupName } from "./dashboardUtils";
import { getMachineTitle, getTabTitle, getUserName } from "./selectors";
import {
  connectToMachine,
  machineSelector,
  resetErrors,
  setOpenRequestLoginsModal,
  setOpenRequestRebootModal,
} from "./slices/machineSlice";
import {
  logOffUserSession,
  userSessionsSelector,
} from "./slices/userSessionsSlice";

const RoleDetailsContainer = React.lazy(() =>
  componentLoader(() => import("./components/admins/RoleDetailsContainer")),
);

const AdminDashboard = React.lazy(() =>
  componentLoader(() => import("./components/admins/AdminDashboard")),
);
const MachinesContainer = React.lazy(() =>
  componentLoader(() => import("./components/machines/MachinesContainer")),
);
const UserSessionsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/userSessions/UserSessionsContainer"),
  ),
);
const UserSessionDetails = React.lazy(() =>
  componentLoader(() => import("./components/userSessions/UserSessionDetails")),
);
const MachineDetails = React.lazy(() =>
  componentLoader(() => import("./components/machines/MachineDetails")),
);
const MachinePerformance = React.lazy(() =>
  componentLoader(() => import("./components/machines/MachinePerformance")),
);

const MachineUserSessions = React.lazy(() =>
  componentLoader(() => import("./components/machines/MachineUserSessions")),
);

const AdminContainer = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/admins/AdminContainer")),
  ),
);
const SupportAdminContainer = React.lazy(() =>
  componentLoader(() => import("./components/admins/SupportAdminContainer")),
);
const ReleaseAnnouncementsContainer = React.lazy(() =>
  componentLoader(
    () =>
      import("./components/releaseAnnouncements/ReleaseAnnouncementsContainer"),
  ),
);
const UserSessionPerformance = React.lazy(() =>
  componentLoader(
    () => import("./components/userSessions/UserSessionPerformance"),
  ),
);

const ActivityLogsContainer = React.lazy(() =>
  componentLoader(() => import("./components/logs/ActivityLogsContainer")),
);
const OrchestrationLogsContainer = React.lazy(() =>
  componentLoader(() => import("./components/logs/OrchestrationLogsContainer")),
);
const SessionHistoryLogsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/logs/SessionHistoryLogsContainer"),
  ),
);
const UserLogonLogsContainer = React.lazy(() =>
  componentLoader(() => import("./components/logs/UserLogonLogsContainer")),
);

const CustomActionsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/custom-actions/CustomActionsContainer.jsx"),
  ),
);

const CreateNewRoleContainer = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/admins/CreateNewRoleContainer")),
  ),
);

const PoolsTable = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/pools/PoolsTable.jsx")),
  ),
);

const EditPool = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/pools/EditPool.jsx")),
  ),
);

const ENTITY_ACTION_DATA = [
  {
    icon: <img src={MonitorIcon} alt="emptyList" height={"22"} />,
    label: "Connect to machine",
    value: "connectToMachine",
    permissionSet: [
      PERMISSIONS_LIST[
        `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.CONNECT_TO_MACHINE}`
      ][PERMISSIONS_TYPE.ACCESS],
    ],
    handleClick: async (dispatch, { state, params }) => {
      const { selectedPartner } = state.partners;
      const { selectedIdentity } = state.identities;
      const { machine } = state.dashboard.machines;
      const { machineName, siteName } = params;
      const response = await dispatch(
        connectToMachine({
          selectedTenant: getTenantId(selectedPartner),
          organizationId: getIdentityId(selectedIdentity),
          machineName,
          siteName,
          techUser: machine?.properties?.serverType === SERVER_TYPE_PLATFORM,
        }),
      );
      if (response.meta.requestStatus === "fulfilled") {
        window.open(response.payload.data, "_blank");
      }
      setTimeout(() => dispatch(resetErrors), SNACKBAR_DURATION_MS);
    },
  },

  {
    icon: <img src={ConnectedIcon} alt="emptyList" height={"22"} />,
    label: "Connect As",
    value: "connectAs",
    permissionSet: [
      PERMISSIONS_LIST[
        `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.CONNECT_AS_TO_MACHINE}`
      ][PERMISSIONS_TYPE.ACCESS],
    ],
    handleClick: async (dispatch, { state, params }) => {
      const { selectedPartner } = state.partners;
      const { selectedIdentity } = state.identities;
      const { machineName, siteName } = params;
      const response = await dispatch(
        connectToMachine({
          selectedTenant: getTenantId(selectedPartner),
          organizationId: getIdentityId(selectedIdentity),
          machineName,
          siteName,
          techUser: true,
        }),
      );
      if (response.meta.requestStatus === "fulfilled") {
        window.open(response.payload.data, "_blank");
      }
      setTimeout(() => dispatch(resetErrors), SNACKBAR_DURATION_MS);
    },
  },
  // TODO: Temporarly commented, till we have this functionality ready
  // {
  //   label: "Run Script",
  //   value: "Run Script",
  // },
  // {
  //   label: "Backup",
  //   value: "Backup",
  // },
  // {
  //   label: "Run Security Scan",
  //   value: "Run Security Scan",
  // },
  // {
  //   label: "Isolate from Network",
  //   value: "Isolate from Network",
  // },
  {
    icon: <RefreshIcon fontSize="small" />,
    label: "Reboot",
    value: "Reboot",
    permissionSet: [
      PERMISSIONS_LIST[
        `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.REBOOT}`
      ][PERMISSIONS_TYPE.ACCESS],
    ],
    handleClick: async (dispatch) => {
      dispatch(setOpenRequestRebootModal(true));
    },
  },
  {
    icon: <img src={DisableLogin} alt="emptyList" />,
    label: "Disable Logins",
    value: "disableLogins",
    permissionSet: [
      PERMISSIONS_LIST[
        `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.ENABLE_DISABLE_LOGIN}`
      ][PERMISSIONS_TYPE.ACCESS],
    ],
    hideOption: (stateSelectedMachine, params) => {
      const machine = _.get(
        stateSelectedMachine,
        `${params.tenantId}.${params.machineName?.toLowerCase()}`,
        {},
      );
      return machine?.properties &&
        machine?.sessionHostProperties &&
        machine?.properties?.status === "Available"
        ? !machine.sessionHostProperties.properties?.allowNewSession
        : true;
    },
    hideOptionDataSelector: machineSelector,
    handleClick: async (dispatch) => {
      dispatch(
        setOpenRequestLoginsModal({ value: true, operation: "Disable" }),
      );
    },
  },
  {
    icon: <img src={EnableLogin} alt="emptyList" />,
    label: "Enable Logins",
    value: "enableLogins",
    permissionSet: [
      PERMISSIONS_LIST[
        `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.ENABLE_DISABLE_LOGIN}`
      ][PERMISSIONS_TYPE.ACCESS],
    ],
    hideOption: (stateSelectedMachine, params) => {
      const machine = _.get(
        stateSelectedMachine,
        `${params.tenantId}.${params.machineName?.toLowerCase()}`,
        {},
      );
      return machine?.properties &&
        machine?.sessionHostProperties &&
        machine?.properties?.status === "Available"
        ? machine.sessionHostProperties.properties?.allowNewSession
        : true;
    },
    hideOptionDataSelector: machineSelector,
    handleClick: async (dispatch) => {
      dispatch(setOpenRequestLoginsModal({ value: true, operation: "Enable" }));
    },
  },
];

const backComponent = () => <Back label="Back" />;

const DashboardRoutes = [
  {
    path: "/admin",
    component: ParentRoute,
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: false,
    adminView: true,
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/admin",
        exact: true,
        isTab: true,
        adminView: true,
        title: "Overview",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminDashboard} />
        ),
        roles: [...SUPPORT_ROLES],
      },
      {
        path: "/admin/manage",
        exact: true,
        redirectTo: "/admin/manage/users",
        adminView: true,
        isTab: true,
        title: "Admins",
        orgRoles: [ORG_ROLES.ORG_ADMIN],
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminContainer} />
        ),
      },
      {
        path: "/admin/manage/:tab",
        exact: true,
        adminView: true,
        title: getTabTitle,
        orgRoles: [ORG_ROLES.ORG_ADMIN],
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminContainer} />
        ),
      },
      {
        path: "/admin/support/support-users",
        exact: true,
        adminView: true,
        isTab: true,
        title: "Support Users",
        isPrivate: true,
        component: () =>
          SuspenseLoader(() => (
            <ProtectedRouteSuspenseContainer
              component={SupportAdminContainer}
            />
          )),
        roles: [SUPPORT_ADMIN],
      },
      {
        path: "/admin/release-announcements",
        exact: true,
        adminView: true,
        isTab: true,
        title: "Release Announcements",
        isPrivate: true,
        component: () =>
          SuspenseLoader(() => (
            <ProtectedRouteSuspenseContainer
              component={ReleaseAnnouncementsContainer}
            />
          )),
        roles: [SUPPORT_ADMIN],
      },
    ],
  },
  {
    path: "/roles",
    title: "Roles",
    component: ParentRoute,
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    routeTitleComponent: () => <DashBoardRouteTitle title="Manage Role" />,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: false,
    nobreadcrumbs: true,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/roles/create",
        title: "Create",
        exact: true,
        adminView: true,
        backComponent,
        component: () => (
          <ProtectedRouteSuspenseContainer component={CreateNewRoleContainer} />
        ),
        roles: [...SUPPORT_ROLES],
      },
      {
        path: "/roles/:roleId",
        exact: true,
        adminView: true,
        isTab: false,
        title: "Roles",
        backComponent,
        component: () => (
          <ProtectedRouteSuspenseContainer component={RoleDetailsContainer} />
        ),
      },
    ],
  },
  {
    path: "/tenants",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Overview",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/tenants/edit/:tenantId",
        exact: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditTenant} />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard/pools/edit",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Pools",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/dashboard/pools/edit/:hypervisorType/:resourcePoolId",
        exact: true,
        renderCTA: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditPool} />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: true,
    title: "Overview",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/dashboard/pools",
        title: "Pools",
        isTab: true,
        disabled: false,
        component: ParentRoute,
        renderCTA: true,
        isSubComponent: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.RESOURCE_POOLS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/dashboard/pools",
            title: "Pools",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={PoolsTable} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.RESOURCE_POOLS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
        ],
      },
      {
        path: "/dashboard/userSessions",
        title: "User sessions",
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        isSubComponent: true,
        component: UserSessionWrapper,
        routes: [
          {
            path: "/dashboard/userSessions",
            title: "User sessions",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={UserSessionsContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/dashboard/userSessions/:param",
            title: getUserName,
            component: UserSessionDetailsWrapper,
            isSubTabs: true,
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
            actions: (
              <EntityActionDropdown
                displayText="Quick Actions"
                dontShowBorder={true}
                dropdownData={[
                  {
                    label: "Log Off",
                    value: "logOffUserSession",
                    permissionSet: [
                      PERMISSIONS_LIST[
                        `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.LOGOFF}`
                      ][PERMISSIONS_TYPE.ACCESS],
                    ],
                    hideOption: (userSessions, params) => {
                      if (userSessions) {
                        return (
                          userSessions.find(
                            (u) =>
                              u.properties.userPrincipalName === params.param,
                          )?.type !== "Spot Group"
                        );
                      }
                      return true;
                    },
                    hideOptionDataSelector: userSessionsSelector,
                    handleClick: async (dispatch, { state, params }) => {
                      const { selectedPartner } = state.partners;
                      const { selectedIdentity } = state.identities;
                      const { param } = params;
                      const { userSessions } = state.dashboard.userSessions;
                      const selectedUserSession = userSessions[
                        getTenantId(selectedPartner)
                      ].find((s) => s.properties?.userPrincipalName === param);
                      const userSessionId = selectedUserSession.userSessionId;
                      const siteName = getSiteName(
                        selectedUserSession.virtualMachineId,
                      );
                      const spotGroupName = getSpotGroupName(
                        selectedUserSession.spotGroupId,
                      );
                      await dispatch(
                        logOffUserSession({
                          tenantId: getTenantId(selectedPartner),
                          organizationId: getIdentityId(selectedIdentity),
                          siteName,
                          spotGroupName,
                          userSessions: [
                            {
                              siteName,
                              spotGroupName,
                              userSessionId,
                            },
                          ],
                        }),
                      );
                    },
                  },
                ]}
              />
            ),
            routes: [
              {
                path: "/dashboard/userSessions/:param",
                exact: true,
                isTab: true,
                title: "Performance",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserSessionDetails}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PERFORMANCE}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/userSessions/:param/processes",
                exact: true,
                isTab: true,
                title: "Processes",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserSessionPerformance}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PROCESSES}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/userSessions/:param/userLogonTime",
                isTab: true,
                title: "User Logon Activity",
                exact: true,
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserLogonLogsContainer}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
                roles: [...SUPPORT_ROLES],
              },
              // TODO: Temporarly commented, till we have this functionality ready
              // {
              //   path: "/dashboard/userSessions/:param/chat-history",
              //   exact: true,
              //   isTab: true,
              //   disabled: true,
              //   title: "Chat History (coming soon)",
              //   component: () => (
              //     <ProtectedRoute component={() => <ChatHistory showTexting={true} />} />
              //   ),
              // },
              // {
              //   path: "/dashboard/userSessions/:param/applications",
              //   exact: true,
              //   isTab: true,
              //   disabled: true,
              //   title: "Applications (coming soon)",
              //   component: () => <ProtectedRoute component={() => <UserSessionDetails />} />,
              // },
            ],
          },
        ],
      },
      {
        path: "/dashboard/machines",
        title: "Machines",
        isTab: true,
        component: MachinesWrapper,
        isSubComponent: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.VIRTUAL_MACHINES][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/dashboard/machines",
            title: "Machines",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={MachinesContainer} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.VIRTUAL_MACHINES][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/dashboard/machines/:machineName/:siteName",
            title: getMachineTitle,
            component: MachinesDetailsWrapper,
            isSubTabs: true,
            actions: (
              <EntityActionDropdown
                displayText="Quick Actions"
                dontShowBorder={true}
                dropdownData={ENTITY_ACTION_DATA}
              />
            ),
            routes: [
              {
                path: "/dashboard/machines/:machineName/:siteName",
                exact: true,
                isTab: true,
                title: "Performance",
                component: () => (
                  <ProtectedRouteSuspenseContainer component={MachineDetails} />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PERFORMANCE}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              // TODO: Temporarly commented, till we have this functionality ready
              // {
              //   path: "/dashboard/machines/:machineName/:siteName/applications",
              //   exact: true,
              //   isTab: true,
              //   disabled: true,
              //   title: "Applications (coming soon)",
              //   component: () => <ProtectedRoute component={() => <MachineDetails />} />,
              // },
              {
                path: "/dashboard/machines/:machineName/:siteName/processes",
                exact: true,
                isTab: true,
                title: "Processes",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={MachinePerformance}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.PROCESSES}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/machines/:machineName/:siteName/activeSessions",
                exact: true,
                isTab: true,
                title: "Active Sessions",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={MachineUserSessions}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.ACTIVE_SESSIONS}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/machines/:machineName/:siteName/userLogonActivity",
                exact: true,
                isTab: true,
                title: "User Logon Activity",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserLogonLogsContainer}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/machines/:machineName/:siteName/customActions",
                exact: true,
                isTab: true,
                title: "Custom Actions",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={CustomActionsContainer}
                    label={"MACHINE_DETAIL_VIEW"}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.VIRTUAL_MACHINES}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              // TODO: Temporarly commented, till we have this functionality ready
              // {
              //   path: "/dashboard/machines/:machineName/:siteName/message-history",
              //   exact: true,
              //   isTab: true,
              //   disabled: true,
              //   title: "Message History (coming soon)",
              //   component: () => <ProtectedRoute component={() => <ChatHistory />} />,
              // },
            ],
          },
        ],
      },
      {
        path: "/dashboard/logs",
        title: "Logs",
        isTabs: true,
        isTab: true,
        isSubTabs: false,
        component: ParentRoute,
        isSubComponent: true,
        roles: [...SUPPORT_ROLES],
        isAutoHeight: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.LOGS}`][PERMISSIONS_TYPE.VIEW],
          PERMISSIONS_LIST[
            `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
          ][PERMISSIONS_TYPE.VIEW],
          PERMISSIONS_LIST[
            `${MODULES_LIST.LOGS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/dashboard/logs",
            isTab: true,
            isSubTabs: true,
            title: "Admin activity",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={ActivityLogsContainer}
              />
            ),
            roles: [...SUPPORT_ROLES],
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            path: "/dashboard/logs/orchestration",
            isTab: true,
            isSubTabs: true,
            title: "Orchestration",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={OrchestrationLogsContainer}
              />
            ),
            roles: [...SUPPORT_ROLES],
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
              ][PERMISSIONS_TYPE.VIEW], // TODO: Change
            ],
          },
          {
            path: "/dashboard/logs/sessionHistory",
            isTab: true,
            isSubTabs: true,
            title: "Session History",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={SessionHistoryLogsContainer}
              />
            ),
            roles: [...SUPPORT_ROLES],
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
          },
        ],
      },
    ],
  },
];

export default DashboardRoutes;
