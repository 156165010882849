import React, { Component } from "react";
import appInsights from "../../appInsights";
import ErrorPage from "./ErrorPage";
import ConfirmModal from "./Modal";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      eventId: null,
      showChunkLoaderHandler: false,
      showFailedToFetchHandler: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    // log error to App Insights
    const errorInfoString = JSON.stringify(errorInfo);

    appInsights.trackException({
      exception: error,
      properties: {
        // Add errorInfo as a custom property
        errorInfo: errorInfoString,
        componentName: this.constructor.name,
        // Assuming `path` is available through props or context
        currentPath: this.props.path || "Unknown path",
      },
    });

    if (error.name === "ChunkLoadError") {
      // open up a modal that asks user to refresh
      this.setState({ showChunkLoaderHandler: true, error: null });
      return;
    } else if (
      error.message.includes("Failed to fetch dynamically imported module")
    ) {
      // open up a modal that asks user to refresh
      this.setState({ showFailedToFetchHandler: true, error: null });
      return;
    }

    this.setState({
      error,
      showChunkLoaderHandler: false,
      showFailedToFetchHandler: false,
    });
    this.setState({ error });

    console.error(error);
    console.error("additional info: ", errorInfo);
  }

  componentDidUpdate(prevState) {
    if (
      !prevState.showFailedToFetchHandler &&
      this.state.showFailedToFetchHandler
    ) {
      // Auto reload the page after a short delay
      window.location.reload();
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return <ErrorPage />;
    }
    if (this.state.showChunkLoaderHandler) {
      return (
        <ConfirmModal
          title={"Unable to Load Page Content"}
          description1={
            "Looks like we are unable to load the content for this page. This is likely due to a network issue or the requested content no longer exists. Try reloading the app to resolve this issue. Contact the support team if you continue to get this error."
          }
          open={this.state.showChunkLoaderHandler}
          onConfirm={() => window.location.reload()}
          showPrimary={true}
          primaryText={"Reload"}
          showSecondary={false}
        />
      );
    }
    if (this.state.showFailedToFetchHandler) {
      return null;
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}
