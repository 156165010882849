import { Box, Grid, Typography, styled } from "@mui/material";
import { IconInfo, IconTrash, Password, useToast } from "@veneer/core";
import "@veneer/styles/typography/typography.css";
import { default as React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CardDetails from "../../../common/components/CardDetails";
import DialogWithLoader from "../../../common/components/DialogWithLoader";
import ConfirmModal from "../../../common/components/Modal";
import VeneerButton from "../../../common/components/VeneerButton";
import SelectInput from "../../../common/components/form/SelectInput";
import TextInput from "../../../common/components/form/TextInput";
import {
  HP_WORKFORCE_INTEGRATION_INSTRUCTIONS,
  HYPERVISOR_TYPES,
} from "../../../common/constants";
import { useAzureTenantId } from "../../../common/hooks/useAzureTenantId";
import useCurrentTenant from "../../../common/hooks/useCurrentTenant";
import {
  deletePartner,
  updateTenantInPartnerList,
} from "../../../common/partnersSlice";
import ShimmerLoader from "../../../common/shimmerLoader/ShimmerLoader";
import { getIdentityId, getTenantId } from "../../../common/utils";
import { isValidCpin } from "../../../common/validators";
import { LOGOUT_INTERVALS } from "../../dashboardConstants";
import { getTenantDetail, getTimeZones } from "../../slices";
import {
  getAdCredentials,
  saveADCredentials,
  updateTenant,
} from "../../slices/tenantSlice";
import EditTenantAdCredentialsModal from "./EditTenantAdCredentialsModal";

const CURRENT_LOGOUT_INTERVALS = LOGOUT_INTERVALS.map((el) => ({
  value: el.value,
  label: el.name,
}));

const ID_MAX_LENGTH = 256;
const TOOLTIP_TEXT = `Unique ID for this tenant in Anyware Manager; up to ${ID_MAX_LENGTH} characters. Alphanumerical and special characters allowed.`;

const Divider = styled("div")(() => ({
  width: "100%",
  marginTop: "1.5rem",
  borderBottom: "1px solid rgb(0, 0, 0, 0.2)",
}));

const PREFIX = "EditTenant";

const classes = {
  label: `${PREFIX}-label`,
  cpin: `${PREFIX}-cpin`,
  iconInfo: `${PREFIX}-iconInfo`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.label}`]: {
    color: theme.palette.foreground.light,
    margin: "0",
    padding: "0",
  },

  [`& .${classes.cpin}`]: {
    marginTop: "4px",
  },

  [`& .${classes.iconInfo}`]: {
    marginTop: "-20px",
    marginRight: "5px",
    color: theme.palette.foreground.light,
  },
}));

const EditTenant = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingTenant, setIsUpdatingTenant] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [availableTimezones, setAvailableTimezones] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const authSession = useSelector((state) => state.auth.auth);
  const currentTenantGeneralInfo = useCurrentTenant();
  const {
    tenant,
    timeZones,
    loadingTenant,
    dataAddCredentials,
    loadingAdCredentials,
  } = useSelector((state) => state.dashboard.tenant);
  const { tenants, deletingTenant, saving } = useSelector(
    (state) => state.partners,
  );
  const { azureTenantId } = useAzureTenantId();

  const currentTenantFriendlyName =
    currentTenantGeneralInfo && currentTenantGeneralInfo.displayName;
  const partner = tenants[getIdentityId(selectedIdentity)];
  const partnerState = partner?.find((partner) => {
    return partner.id === selectedPartner;
  });
  const isTenantVsphere =
    partnerState?.tenantOnboardingType === HYPERVISOR_TYPES.VCENTER;

  const {
    control,
    setValue,
    formState: { isDirty, isValid },
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      customId: "",
      displayName: "",
      sessionTimeout: {},
      timezone: {},
      cpin: "",
    },
  });

  useEffect(() => {
    if (timeZones && timeZones.length) {
      setAvailableTimezones(
        timeZones.map((el) => ({ value: el.value, label: el.key })),
      );
    }
  }, [timeZones, setAvailableTimezones]);

  useEffect(() => {
    if (isTenantVsphere) {
      const request = async () => {
        const organizationId = getIdentityId(selectedIdentity);
        const tenantId = getTenantId(selectedPartner);
        if (organizationId && tenantId) {
          await dispatch(
            getAdCredentials({
              organizationId,
              tenantId,
            }),
          ).unwrap();
        }
      };
      request();
    }
  }, [dispatch, isTenantVsphere, selectedIdentity, selectedPartner]);

  useEffect(() => {
    dispatch(getTimeZones({ useCache: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTenantDetail({
        organizationId: getIdentityId(selectedIdentity),
        tenantId: getTenantId(selectedPartner),
        useCache: true,
      }),
    );
  }, [authSession, selectedPartner, selectedIdentity, dispatch]);

  useEffect(() => {
    if (tenant && tenant[getTenantId(selectedPartner)]) {
      const currentTenant = tenant[getTenantId(selectedPartner)];
      const timeout = CURRENT_LOGOUT_INTERVALS.find(
        (interval) =>
          interval.value === currentTenant.sessionIdleTimeoutMinutes,
      );

      if (currentTenant.timeZone) {
        setValue("timezone", {
          label: currentTenant.timeZone,
          value: currentTenant.timeZone,
        });
      }

      if (currentTenantFriendlyName) {
        setValue("displayName", currentTenantFriendlyName);
      }

      if (timeout) {
        setValue("sessionTimeout", timeout);
      }

      if (currentTenant.customId) {
        setValue("customId", currentTenant.customId);
      }

      if (currentTenant.cpin) {
        setValue("cpin", currentTenant.cpin);
      }
      setIsLoading(false);
    }
  }, [tenant, selectedPartner, setValue, currentTenantFriendlyName]);

  const handleDelete = async (shouldDelete) => {
    setShowDeleteDialog(false);
    if (shouldDelete) {
      const { meta } = await dispatch(
        deletePartner({
          selectedOrganization: getIdentityId(selectedIdentity),
          selectedTenant: getTenantId(selectedPartner),
        }),
      );

      const tenantName =
        currentTenantFriendlyName || getTenantId(selectedPartner);

      const message =
        meta.requestStatus === "fulfilled"
          ? `${tenantName} tenant deleted successfully`
          : `Unable to delete ${tenantName} tenant.`;

      const type = meta.requestStatus === "fulfilled" ? "positive" : "negative";

      addToast({
        text: message,
        type: type,
        timeout: 6,
        id: Date.now(),
      });

      if (meta.requestStatus === "fulfilled") {
        history.replace("/");
      }
    } else {
      setShowDeleteDialog(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = async () => {
    setIsUpdatingTenant(true);

    const formData = getValues();
    const { meta } = await dispatch(
      updateTenant({
        organizationId: getIdentityId(selectedIdentity),
        tenantId: getTenantId(selectedPartner),
        payload: {
          customId: formData.customId,
          displayName: formData.displayName,
          sessionIdleTimeoutMinutes: formData.sessionTimeout
            ? formData.sessionTimeout.value
            : 0,
          timeZone: formData.timezone.value,
          cpin: formData.cpin,
          emailDisabled: false,
          notificationsDisabled: false,
          supportAccessEnabled: false,
          supportAccessExpirationDateUTC: null,
        },
      }),
    );

    const metaUpdateTenant = await meta.requestStatus;

    if (metaUpdateTenant === "rejected") {
      addToast({
        text: "Failed to update tenant",
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
    }

    if (metaUpdateTenant === "fulfilled") {
      await dispatch(
        updateTenantInPartnerList({
          tenant: {
            ...tenant[getTenantId(selectedPartner)],
            displayName: formData.displayName,
          },
          selectedOrganization: getIdentityId(selectedIdentity),
        }),
      );
      addToast({
        text: "Tenant updated successfully",
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }

    setIsUpdatingTenant(false);
  };

  const handleConfirm = async (data) => {
    try {
      const result = await dispatch(
        saveADCredentials({
          organizationId: getIdentityId(selectedIdentity),
          tenantId: getTenantId(selectedPartner),
          username: data.username,
          password: data.password,
        }),
      );

      const { meta, payload } = result;
      const metaUpdateTenant = await meta.requestStatus;

      if (metaUpdateTenant === "rejected") {
        addToast({
          text: payload?.message || "Failed to update tenant",
          type: "negative",
          timeout: 6,
          id: Date.now(),
        });
      }

      if (metaUpdateTenant === "fulfilled") {
        handleCloseModal();
        addToast({
          text: "Tenant updated successfully",
          type: "positive",
          timeout: 6,
          id: Date.now(),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Root>
      {isLoading ? (
        <ShimmerLoader type="table" />
      ) : (
        <Box display="flex" justifyContent="flex-start" gap={"40px"}>
          <Grid
            container
            sx={{ maxWidth: "764px" }}
            spacing={2}
            paddingBottom={2}
          >
            <Grid item xs={12}>
              <form>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={"column"}
                  gap={3}
                >
                  <Controller
                    name="displayName"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label="Name"
                        disabled={loadingTenant || isUpdatingTenant}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="customId"
                    control={control}
                    render={({ field, formState: { errors } }) => (
                      <TextInput
                        label="Custom ID"
                        tooltip={TOOLTIP_TEXT}
                        errors={errors}
                        disabled={loadingTenant || isUpdatingTenant}
                        {...field}
                      />
                    )}
                    rules={{
                      maxLength: {
                        value: ID_MAX_LENGTH,
                        message: `Up to ${ID_MAX_LENGTH} characters allowed`,
                      },
                    }}
                  />

                  <Controller
                    name="sessionTimeout"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        label="Web UI Service Timeout"
                        options={CURRENT_LOGOUT_INTERVALS}
                        onClear={() => setValue("sessionTimeout", {})}
                        disabled={loadingTenant || isUpdatingTenant}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field }) => (
                      <SelectInput
                        label="Timezone"
                        isSearchable
                        isSliceDisplay
                        options={availableTimezones}
                        onClear={() => setValue("timezone", {})}
                        disabled={
                          !availableTimezones.length || isUpdatingTenant
                        }
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="cpin"
                    control={control}
                    rules={{
                      validate: (value) =>
                        !value || isValidCpin(value) || "Invalid Company PIN",
                    }}
                    render={({ field, formState: { errors } }) => (
                      <Box>
                        <label className={classes.label}>
                          HP Workforce Experience Company PIN
                        </label>
                        <Password
                          disabled={loadingTenant || isUpdatingTenant}
                          className={classes.cpin}
                          style={{ paddingLeft: "15px" }}
                          value={field.value}
                          error={!!errors.cpin}
                          helperText={errors.cpin?.message}
                          {...field}
                        />
                        <Box
                          display="flex"
                          flexDirection="row"
                          marginTop="10px"
                        >
                          <IconInfo
                            className={classes.iconInfo}
                            filled
                            size={62}
                          />
                          <Typography color="#737373">
                            {HP_WORKFORCE_INTEGRATION_INSTRUCTIONS}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
              </form>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <VeneerButton
                    appearance="secondary"
                    colorScheme="negative"
                    leadingIcon={<IconTrash />}
                    onClick={() => setShowDeleteDialog(true)}
                    disabled={isUpdatingTenant}
                  >
                    Delete tenant
                  </VeneerButton>
                </Grid>

                <Grid item>
                  <Grid container>
                    <Grid mr={1}>
                      <VeneerButton
                        appearance="secondary"
                        onClick={() => {
                          if (history.length) {
                            history.goBack();
                          } else {
                            history.replace("/");
                          }
                        }}
                        disabled={isUpdatingTenant}
                      >
                        Cancel
                      </VeneerButton>
                    </Grid>

                    <Grid>
                      <VeneerButton
                        disabled={!isDirty || !isValid || isUpdatingTenant}
                        onClick={handleEdit}
                      >
                        Save
                      </VeneerButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} flex={1}>
            {tenant[getTenantId(selectedPartner)] && (
              <div style={{ marginBottom: "24px" }}>
                <CardDetails
                  title="Tenant Details"
                  details={[
                    {
                      label: "Anyware Tenant ID",
                      value: tenant[getTenantId(selectedPartner)].id.replace(
                        "/tenants/",
                        "",
                      ),
                    },
                    { label: "Entra tenant ID", value: azureTenantId },
                  ]}
                  isCopiable
                />
              </div>
            )}
            {!loadingAdCredentials && isTenantVsphere && (
              <div>
                <CardDetails
                  title="Active Directory details"
                  onAddClick={handleOpenModal}
                  details={
                    dataAddCredentials
                      ? [{ label: "Username", value: dataAddCredentials }]
                      : []
                  }
                  isEditable={!!dataAddCredentials}
                />
              </div>
            )}
          </Grid>
          {isModalOpen && (
            <EditTenantAdCredentialsModal
              isModalOpen={isModalOpen}
              handleCloseModal={handleCloseModal}
              adCredentials={dataAddCredentials}
              onConfirm={handleConfirm}
            />
          )}
          {!!currentTenantGeneralInfo && showDeleteDialog && (
            <ConfirmModal
              title={`Delete ${currentTenantFriendlyName || getTenantId(selectedPartner)}?`}
              description1={
                "Deleting this tenant will not remove any machines, resources, or configurations created by the Anyware Manager Enterprise from your Azure tenant or VMware server."
              }
              manualReverify={true}
              verificationString={
                currentTenantFriendlyName || getTenantId(selectedPartner)
              }
              open={showDeleteDialog}
              onConfirm={handleDelete}
              onClose={() => setShowDeleteDialog(false)}
              showPrimary={true}
              primaryText={"Delete"}
              showSecondary={true}
              secondaryText={"Cancel"}
              destructive
            />
          )}
          <DialogWithLoader
            open={deletingTenant || saving}
            title="Please Wait"
            loaderText={
              deletingTenant ? "Deleting tenant..." : "Please wait..."
            }
          />
        </Box>
      )}
    </Root>
  );
};

export default EditTenant;
