import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../../../common/components/Alert";
import DialogWithLoader from "../../../../common/components/DialogWithLoader";
import { getIdentityId, getTenantId } from "../../../../common/utils";
import { checkCloudPc } from "../../../deschutes/deschutesSlice";
import { checkW365NotEnabled } from "../../deschutesUtils";

export default function CheckW365Enabled({ childComponent: Component }) {
  const { auth: authSession } = useSelector((state) => state.auth);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const dispatch = useDispatch();
  const { loadingCheckCloudPc, cloudPcEnabled } = useSelector(
    (state) => state.adminActions.deschutes,
  );

  useEffect(() => {
    if (authSession && selectedPartner && selectedIdentity) {
      const tenanttId = getTenantId(selectedPartner);
      const organizationId = getIdentityId(selectedIdentity);
      dispatch(
        checkCloudPc({
          useCache: true,
          selectedTenant: tenanttId,
          organizationId,
        }),
      );
    }
  }, [authSession, selectedPartner, selectedIdentity]);

  return (
    <>
      <DialogWithLoader
        open={loadingCheckCloudPc}
        title="Please Wait..."
        loaderText={
          loadingCheckCloudPc ? "Checking Windows365" : "Loading Data"
        }
      />
      {selectedPartner &&
        checkW365NotEnabled(getTenantId(selectedPartner), cloudPcEnabled) && (
          <Alert
            dataTestIdPrefix={"check-win365-enabled-alert"}
            message={
              "It appears that this tenant does not have the Windows 365 enterprise application required for Windows 365 - therefore, Windows 365 workflows will not function within AME. Please ensure that the Windows 365 enterprise application is present, then return to this workflow."
            }
            severity={"error"}
          />
        )}
      {<Component />}
    </>
  );
}
