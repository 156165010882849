import PropTypes from "prop-types";
import React from "react";
import {
  mapTenantIcon,
  mapTenantLabel,
  mapTenantTagType,
} from "../../../common/components/utils/tenantStatusMap";
import { TagIndicator } from "./TagIndicator";

export const TenantsTag = ({ status }) => {
  const label = mapTenantLabel(status);
  const type = mapTenantTagType(status);
  const leadingIcon = mapTenantIcon(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

TenantsTag.propTypes = {
  // // TODO: TSW-225873: Once the backend is adjusted to follow the naming convention for the status
  // update the propTypes to match the backend
  status: PropTypes.oneOf([
    "available",
    "finalizing",
    "initializing",
    "pending",
  ]),
};
