import { useToast } from "@veneer/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../notificationSlice";

const NotificationManager = () => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    notifications?.forEach((notification) => {
      addToast({
        text: notification.text,
        type: notification.type,
        timeout: notification.timeout,
        id: notification.id,
      });

      dispatch(removeNotification(notification.id));
    });
  }, [notifications, addToast, dispatch]);

  return null;
};

export default NotificationManager;
