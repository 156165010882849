import { useTheme } from "@mui/material/styles";
import React from "react";

export const HPIconLarge = () => {
  const theme = useTheme();
  const logoColor = theme.palette.foreground.default;

  return (
    <svg
      width="250"
      height="32"
      viewBox="0 0 450 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13231_2708)">
        <path
          d="M27.5 55C27.2265 55 26.9544 54.995 26.6828 54.9872L32.3028 39.5416H40.0372C41.3947 39.5416 42.8849 38.498 43.3492 37.223L49.4465 20.4753C50.444 17.7333 48.8735 15.49 45.9558 15.49H35.2258L26.2227 40.2293H26.2193L21.1155 54.2534C9.00678 51.3745 0 40.4896 0 27.5001C0 14.885 8.49447 4.25503 20.0753 1.01516L14.8089 15.49H14.8074L6.05243 39.5416L11.8807 39.5421L19.3262 19.1042H23.7064L16.2608 39.5421L22.0868 39.5431L29.0253 20.4753C30.0234 17.7333 28.4527 15.49 25.5378 15.49H20.6386L26.264 0.0289004C26.674 0.0107581 27.0857 0 27.5 0C42.6879 0 55 12.3121 55 27.5001C55 42.6877 42.6879 55 27.5 55ZM44.1391 19.0895H39.7601L33.6278 35.9151H38.0068L44.1391 19.0895Z"
          fill={logoColor}
        />
      </g>
      <path
        d="M110 20V40H99.6712V21.1111C99.6712 15.9259 95.3422 11.7185 90 11.7185C84.6596 11.7185 80.3288 15.9241 80.3288 21.1111V40H70V20C70 14.6957 72.1077 9.60859 75.8595 5.85786C79.6113 2.10714 84.6998 0 90.0056 0C95.3094 0.00294578 100.395 2.11138 104.144 5.86179C107.894 9.6122 110 14.6976 110 20ZM99.6712 39.9889H80.3288L90.0056 55L99.6712 39.9889Z"
        fill={logoColor}
      />
      <g clipPath="url(#clip1_13231_2708)">
        <path
          d="M284.286 40.5248C281.815 40.5248 279.73 39.9998 278.031 38.9496C276.333 37.8686 275.051 36.4015 274.186 34.5483C273.321 32.6643 272.889 30.5177 272.889 28.1086C272.889 25.6068 273.337 23.4293 274.232 21.5761C275.128 19.692 276.41 18.2249 278.078 17.1748C279.746 16.0938 281.738 15.5533 284.054 15.5533C287.514 15.5533 290.201 16.6652 292.116 18.889C294.061 21.1128 295.081 24.2014 295.173 28.1549C295.173 28.4329 295.034 28.5718 294.756 28.5718H276.178C275.9 28.5718 275.761 28.4329 275.761 28.1549V26.1164C275.761 25.8384 275.9 25.6994 276.178 25.6994H290.587C290.556 24.3713 290.293 23.1668 289.799 22.0857C289.305 21.0047 288.579 20.1399 287.622 19.4913C286.664 18.8118 285.49 18.472 284.101 18.472C281.784 18.472 280.039 19.3369 278.865 21.0665C277.723 22.7652 277.151 25.1126 277.151 28.1086C277.151 31.1663 277.738 33.5137 278.912 35.1506C280.116 36.7567 281.923 37.5598 284.332 37.5598C286.093 37.5598 287.498 37.0965 288.548 36.1699C289.598 35.2124 290.293 33.838 290.633 32.0466C290.726 31.7995 290.896 31.6759 291.143 31.6759H294.339C294.679 31.6759 294.818 31.7995 294.756 32.0466C294.509 33.6835 293.938 35.1506 293.042 36.4478C292.177 37.7142 291.004 38.718 289.521 39.4593C288.069 40.1696 286.324 40.5248 284.286 40.5248Z"
          fill={logoColor}
        />
        <path
          d="M259.48 39.9689C259.202 39.9689 259.063 39.8299 259.063 39.5519L259.202 27.7842L259.063 16.5262C259.063 16.2482 259.202 16.1092 259.48 16.1092H262.908C263.186 16.1092 263.325 16.2482 263.325 16.5262L263.186 20.6495C264.02 19.0743 265.163 17.8852 266.614 17.0821C268.066 16.2791 269.626 15.8776 271.294 15.8776C271.541 15.8776 271.788 15.893 272.035 15.9239C272.282 15.9548 272.498 15.9857 272.684 16.0166C272.931 16.0474 273.054 16.2019 273.054 16.4798V18.889C273.054 19.167 272.915 19.2905 272.637 19.2596C272.452 19.2287 272.251 19.2133 272.035 19.2133C271.819 19.1824 271.572 19.167 271.294 19.167C269.966 19.167 268.715 19.4449 267.541 20.0009C266.398 20.5259 265.456 21.3599 264.715 22.5027C263.974 23.6146 263.495 25.0353 263.279 26.765L263.371 39.5519C263.371 39.8299 263.232 39.9689 262.954 39.9689H259.48Z"
          fill={logoColor}
        />
        <path
          d="M251.182 39.9689C250.904 39.9689 250.765 39.8299 250.765 39.5519L250.95 33.8534C250.95 33.6372 251.027 33.4828 251.182 33.3901L251.506 33.1121V22.966L251.228 22.7807C251.012 22.6262 250.904 22.4564 250.904 22.271L250.765 16.5262C250.765 16.2482 250.904 16.1092 251.182 16.1092H254.61C254.888 16.1092 255.027 16.2482 255.027 16.5262L254.888 27.7842L255.027 39.5519C255.027 39.8299 254.888 39.9689 254.61 39.9689H251.182ZM243.259 40.4322C241.097 40.4322 239.275 39.8917 237.793 38.8106C236.341 37.6987 235.244 36.2162 234.503 34.363C233.762 32.5098 233.391 30.425 233.391 28.1085C233.391 25.7921 233.777 23.6918 234.549 21.8077C235.322 19.9237 236.449 18.4257 237.932 17.3138C239.414 16.2019 241.205 15.6459 243.306 15.6459C246.333 15.6459 248.664 16.727 250.301 18.889C251.938 21.0201 252.757 24.0779 252.757 28.0622C252.788 30.4405 252.433 32.5716 251.691 34.4557C250.95 36.3089 249.869 37.776 248.448 38.857C247.058 39.9071 245.329 40.4322 243.259 40.4322ZM244.279 37.4671C246.348 37.4671 247.97 36.6949 249.143 35.1506C250.348 33.6063 250.966 31.2898 250.996 28.2012C250.996 25.1435 250.425 22.7807 249.282 21.1128C248.139 19.4449 246.502 18.5956 244.371 18.5647C242.858 18.5647 241.607 18.9662 240.619 19.7693C239.63 20.5414 238.889 21.6224 238.395 23.0123C237.932 24.3713 237.7 25.962 237.7 27.7842C237.7 29.6992 237.932 31.3825 238.395 32.8341C238.889 34.2858 239.615 35.4286 240.572 36.2625C241.561 37.0656 242.796 37.4671 244.279 37.4671Z"
          fill={logoColor}
        />
        <path
          d="M207.467 39.9688C207.189 39.9688 207.019 39.8453 206.957 39.5982L200.842 16.5725C200.749 16.2636 200.872 16.1092 201.212 16.1092H204.594C204.872 16.1092 205.027 16.2327 205.058 16.4798L209.69 36.3088L214.277 16.4798C214.339 16.2327 214.493 16.1092 214.74 16.1092H219.049C219.296 16.1092 219.451 16.2327 219.512 16.4798L224.284 36.0772L228.732 16.4798C228.794 16.2327 228.948 16.1092 229.195 16.1092H232.253C232.593 16.1092 232.716 16.2636 232.624 16.5725L226.786 39.5982C226.724 39.8453 226.57 39.9688 226.323 39.9688H222.153C221.906 39.9688 221.736 39.8453 221.643 39.5982L216.594 19.6766L212.053 39.5982C212.022 39.8453 211.868 39.9688 211.59 39.9688H207.467Z"
          fill={logoColor}
        />
        <path
          d="M185.35 47.8448C185.196 47.8448 185.088 47.7985 185.026 47.7059C184.964 47.6132 184.949 47.5051 184.98 47.3815L187.574 40.0615L178.215 16.5725C178.154 16.4489 178.154 16.3408 178.215 16.2482C178.277 16.1555 178.385 16.1092 178.54 16.1092H182.431C182.678 16.1092 182.833 16.2173 182.895 16.4335L189.659 34.502L195.404 16.4798C195.465 16.2327 195.62 16.1092 195.867 16.1092H199.342C199.496 16.1092 199.604 16.1555 199.666 16.2482C199.728 16.3408 199.743 16.4489 199.712 16.5725L188.964 47.5205C188.902 47.7367 188.748 47.8448 188.501 47.8448H185.35Z"
          fill={logoColor}
        />
        <path
          d="M157.366 39.9689C157.088 39.9689 156.949 39.8299 156.949 39.5519L157.088 29.1278L156.995 16.5262C156.995 16.2482 157.134 16.1092 157.412 16.1092H160.702C160.98 16.1092 161.119 16.2482 161.119 16.5262L161.026 20.2789C161.52 19.445 162.138 18.6728 162.879 17.9624C163.651 17.252 164.532 16.6806 165.52 16.2482C166.539 15.7849 167.682 15.5533 168.948 15.5533C171.419 15.5533 173.365 16.3563 174.786 17.9624C176.238 19.5376 176.963 21.9004 176.963 25.0508V29.7764L177.056 39.5519C177.056 39.8299 176.917 39.9689 176.639 39.9689H173.164C172.886 39.9689 172.747 39.8299 172.747 39.5519L172.84 29.7764V25.1898C172.84 22.966 172.377 21.3445 171.45 20.3252C170.524 19.2751 169.304 18.75 167.79 18.75C166.771 18.75 165.813 19.028 164.918 19.5839C164.053 20.1399 163.296 20.9584 162.648 22.0394C161.999 23.0895 161.52 24.3868 161.211 25.9311V29.7764L161.304 39.5519C161.304 39.8299 161.165 39.9689 160.887 39.9689H157.366Z"
          fill={logoColor}
        />
        <path
          d="M133.32 30.147C133.042 30.147 132.903 30.008 132.903 29.7301V27.1356C132.903 26.8267 133.042 26.6723 133.32 26.6723H147.914C148.192 26.6723 148.331 26.8267 148.331 27.1356V29.7301C148.331 30.008 148.192 30.147 147.914 30.147H133.32ZM126.556 39.9689C126.432 39.9689 126.324 39.9225 126.232 39.8299C126.17 39.7372 126.17 39.6291 126.232 39.5056L137.536 7.86258C137.629 7.64637 137.799 7.53827 138.046 7.53827H143.003C143.25 7.53827 143.404 7.66181 143.466 7.90891L154.678 39.5056C154.74 39.6291 154.74 39.7372 154.678 39.8299C154.616 39.9225 154.508 39.9689 154.354 39.9689H150.694C150.477 39.9689 150.323 39.8453 150.23 39.5982L140.316 10.596L130.54 39.5982C130.478 39.8453 130.324 39.9689 130.077 39.9689H126.556Z"
          fill={logoColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_13231_2708">
          <rect width="55" height="55" fill="white" />
        </clipPath>
        <clipPath id="clip1_13231_2708">
          <rect
            width="169"
            height="55"
            fill="white"
            transform="translate(126)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HPIconSmall = () => {
  const theme = useTheme();
  const logoColor = theme.palette.foreground.default;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_5288_1146)">
        <path
          d="M16 32C15.8409 32 15.6825 31.9971 15.5245 31.9925L18.7943 23.006H23.2943C24.0842 23.006 24.9512 22.3988 25.2214 21.657L28.7689 11.9129C29.3493 10.3176 28.4355 9.01235 26.7379 9.01235H20.495L15.2569 23.4061H15.2549L12.2854 31.5656C5.24031 29.8906 0 23.5576 0 16C0 8.66039 4.94224 2.47565 11.6802 0.590636L8.61607 9.01235H8.6152L3.52141 23.006L6.9124 23.0063L11.2444 11.1152H13.7928L9.46083 23.0063L12.8505 23.0069L16.8874 11.9129C17.4682 10.3176 16.5543 9.01235 14.8584 9.01235H12.0079L15.2809 0.0168148C15.5194 0.00625924 15.759 0 16 0C24.8366 0 32 7.16343 32 16C32 24.8365 24.8366 32 16 32ZM25.6809 11.1066H23.1332L19.5653 20.8961H22.1131L25.6809 11.1066Z"
          fill={logoColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5288_1146">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
