/* eslint-disable no-case-declarations */
import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import {
  IconCheckmark,
  IconPencil,
  IconTrash,
  IconX,
  useToast,
} from "@veneer/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  DISPLAY_POOL_NAME_MESSAGE_RESTRICTION,
  DISPLAY_SITE_NAME_MESSAGE_RESTRICTION,
} from "../../admin-actions/adminActionsConstants";
import {
  setSelectedPoolToDelete,
  updatePool,
} from "../../admin-actions/pools/poolsSlice";
import {
  closeDialogToDeleteSite,
  editSites,
  openDialogToDeleteSite,
} from "../../admin-actions/sites/sitesSlice";
import { getHypervisorDisplayName } from "../../admin-actions/sites/utils/siteMapping";
import {
  ACTION_REQUIRED_TOOLTIP_TEXT,
  mapPoolsTagLabel,
} from "../../dashboard/components/pools/utils/poolStatusMap";
import { PoolTag } from "../../dashboard/components/tags/PoolTag";
import { TagIndicator } from "../../dashboard/components/tags/TagIndicator";
import { TenantsTag } from "../../dashboard/components/tags/TenantsTag";
import { validatePoolName } from "../../onboarding/onboardingUtils";
import { MODULES_LIST, PERMISSIONS_LIST, PERMISSIONS_TYPE } from "../constants";
import useCTA from "../hooks/useCTA";
import useCurrentMachine from "../hooks/useCurrentMachine";
import useCurrentPool from "../hooks/useCurrentPool";
import useCurrentSite from "../hooks/useCurrentSite";
import useCurrentTenant from "../hooks/useCurrentTenant";
import { useOrgAndTenantId } from "../hooks/useOrgAndTenantId";
import { formatLabelToCamelCase, getIdentityId, getTenantId } from "../utils";
import { validateSiteName } from "../validators";
import GenericPageHeader from "./GenericPageHeader";
import OptionsMenuDropdownButton from "./OptionsMenuDropdownButton";
import VeneerButton from "./VeneerButton";
import TextInput from "./form/TextInput";

const inputEditType = {
  pool: "pool",
  site: "site",
};

const EditTitle = ({
  onEdit,
  title,
  disabled,
  onDelete,
  enableDelete,
  buttonDeleteLabel,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        {title}
        {title && (
          <VeneerButton
            appearance="ghost"
            leadingIcon={<IconPencil />}
            onClick={onEdit}
            disabled={disabled}
          />
        )}
      </Box>
      {enableDelete && (
        <Box sx={{ display: "flex", paddingTop: "4px", gap: "12px" }}>
          <VeneerButton
            appearance="secondary"
            colorScheme="negative"
            leadingIcon={<IconTrash />}
            onClick={onDelete}
          >
            {buttonDeleteLabel}
          </VeneerButton>
        </Box>
      )}
    </Box>
  );
};

const EditInputName = ({
  type = "pool",
  onAccept,
  onCancel,
  defaultName = "",
  onDelete,
  loading,
  disabled,
  enableDelete,
  buttonDeleteLabel,
}) => {
  const [value, setValue] = useState(defaultName);
  const infos = {
    site: {
      helperText: DISPLAY_SITE_NAME_MESSAGE_RESTRICTION,
      validate: !validateSiteName(value),
    },
    pool: {
      helperText: DISPLAY_POOL_NAME_MESSAGE_RESTRICTION,
      validate: validatePoolName(value),
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "24px",
          width: "100%",
          justifyContent: "space-between",
          maxWidth: "600px",
        }}
      >
        <TextInput
          isHeader
          error={infos[type].validate}
          helperText={
            value ? infos[type].helperText || "" : "this field is required"
          }
          helperTextVisibility="visible"
          onChange={(e) => setValue(e)}
          defaultValue={defaultName}
          disabled={loading || disabled}
        />
        <Box sx={{ display: "flex", paddingTop: "4px", gap: "12px" }}>
          <VeneerButton
            disabled={!value || infos[type].validate || disabled}
            leadingIcon={<IconCheckmark />}
            onClick={() => onAccept(type, value)}
            loading={loading}
          />
          <VeneerButton
            appearance="secondary"
            colorScheme="negative"
            leadingIcon={<IconX />}
            onClick={onCancel}
          />
        </Box>
      </Box>
      {enableDelete && (
        <Box sx={{ display: "flex", paddingTop: "4px", gap: "12px" }}>
          <VeneerButton
            appearance="secondary"
            colorScheme="negative"
            leadingIcon={<IconTrash />}
            onClick={onDelete}
          >
            {buttonDeleteLabel}
          </VeneerButton>
        </Box>
      )}
    </Box>
  );
};
const PREFIX = "header";
const classes = {
  container: `${PREFIX}-container`,
  innerContainer: `${PREFIX}-innerContainer`,
};

const StyledHeaderContainer = styled("div")(() => ({
  [`&.${classes.container}`]: {
    marginTop: "70px",
    marginBottom: "10px",
    padding: "32px 32px 0",
  },
  [`& .${classes.innerContainer}`]: {
    maxWidth: "1280px",
    margin: "0 auto",
  },
}));

const urlHeaderMap = [
  { url: "/admin", headerType: "Tenants" },
  { url: "/selectService", headerType: "Select service" },
  { url: "/tenants/edit/.*", headerType: "<tenantDetail>" },
  { url: "/admin/manage/users", headerType: "Users" },
  { url: "/dashboard/.*", headerType: "<tenantName>" },
  { url: "/dashboard/machines/.*/.*", headerType: "<machineName>" },
  { url: "/dashboard/pools/.*/.*", headerType: "<poolName>" },
  { url: "/dashboard/pools/addPool/.*", headerType: "Add Pool" },
  { url: "/sites", headerType: "Sites" },
  { url: "/sites/action/edit", headerType: "<sites>" },
  { url: "/sites/action/create/.*", headerType: "<createSite>" },
  { url: "/settings/general", headerType: "Settings" },
  { url: "/settings/licenses", headerType: "Settings" },
  { url: "/extensibility/accounts", headerType: "API accounts" },
  { url: "/onboarding.*", headerType: "<onboardingTitle>" },
];

const ACCOUNTS_SUBTITLE = "Add, edit and manage users";
const COPY_CONNECTION_URL_MESSAGE = "Copy connection URL";

const getSubtitle = (headerType, displayTitle) => {
  switch (headerType) {
    case "<onboardingTitle>":
      return displayTitle === "Add Tenant" ? null : COPY_CONNECTION_URL_MESSAGE;
    case "<tenantName>":
    case "<tenantDetail>":
      return COPY_CONNECTION_URL_MESSAGE;
    case "Accounts":
      return ACCOUNTS_SUBTITLE;
    default:
      return null;
  }
};

const getActions = (headerType, handleViewTenantDetailsClick) => {
  switch (headerType) {
    case "<tenantName>":
    case "<tenantDetail>":
      return (
        <Grid container alignItems="center" justifyContent="end" spacing={1}>
          <Grid item>
            <VeneerButton
              appearance="secondary"
              onClick={handleViewTenantDetailsClick}
            >
              View details
            </VeneerButton>
          </Grid>
          <Grid item>
            <OptionsMenuDropdownButton
              title="Manage"
              options={[
                {
                  title: "Sites",
                  permissionSet: [
                    PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/sites",
                },
                {
                  title: "API Accounts",
                  permissionSet: [
                    PERMISSIONS_LIST[MODULES_LIST.API_ACCOUNTS][
                      PERMISSIONS_TYPE.VIEW
                    ],
                  ],
                  href: "/extensibility/accounts",
                },
              ]}
            />
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

const getDisplayTag = (
  headerType,
  tenantToUse,
  currentPool,
  displayTitle,
  currentSite,
) => {
  const tenantStatus = formatLabelToCamelCase(tenantToUse?.status) || "";
  switch (headerType) {
    case "<tenantName>":
      return tenantStatus ? (
        <>
          <TenantsTag status={tenantStatus} />
        </>
      ) : null;
    case "<tenantDetail>":
    case "<sites>":
      return tenantStatus ? (
        <>
          <TagIndicator
            label={`Type: ${getHypervisorDisplayName(currentPool?.hypervisorType || currentSite?.hypervisorType)}`}
            type="primary"
            style={{ marginRight: "8px" }}
          />
          <TenantsTag status={tenantStatus} />
        </>
      ) : null;
    case "<machineName>":
      return null;
    case "<poolName>":
      const poolStatus = formatLabelToCamelCase(currentPool?.status);
      return poolStatus ? (
        <>
          <TagIndicator
            label={`Type: ${getHypervisorDisplayName(currentPool?.hypervisorType)}`}
            type="primary"
            style={{ marginRight: "8px" }}
          />
          <PoolTag status={poolStatus} />
        </>
      ) : null;
    case "<onboardingTitle>":
      return displayTitle === "Add Tenant" ? null : (
        <TenantsTag status="pending" />
      );
    case "<createSite>":
      const typeAddSite = location.pathname.replace(
        "/sites/action/create/",
        "",
      );

      const tagAddSite = {
        registeredDevices: "Registered devices",
        vmware: "VMware",
        win365: "Windows 365",
      };

      const tagLabel = tagAddSite[typeAddSite];
      return <TagIndicator label={`Type: ${tagLabel}`} type="primary" />;
    default:
      return null;
  }
};

const getDisplayTitle = ({
  headerType,
  displayNameOnboarding,
  tenantToUse,
  currentMachine,
  currentPool,
  currentSite,
  tenantIdOnboarding,
  isResumingOnboarding,
  editMode = false,
  onEdit,
  onCancel,
  onUpdateTitle,
  onDelete,
  loading,
}) => {
  const status = currentPool?.status || currentSite?.status || "";
  const isAvailable = status.toLowerCase() === "available";
  switch (headerType) {
    case "<tenantName>":
    case "<tenantDetail>":
      return tenantToUse?.displayName || displayNameOnboarding;
    case "<onboardingTitle>":
      return tenantIdOnboarding || isResumingOnboarding
        ? displayNameOnboarding
        : "Add Tenant";
    case "<machineName>":
      return currentMachine?.name;
    case "<poolName>":
      return editMode ? (
        <EditInputName
          loading={loading}
          type={inputEditType.pool}
          defaultName={currentPool?.name}
          onCancel={onCancel}
          onAccept={onUpdateTitle}
          onDelete={() =>
            onDelete(inputEditType.pool, currentPool?.name, currentPool?.id)
          }
          buttonDeleteLabel="Delete pool"
          enableDelete={isAvailable}
        />
      ) : (
        <EditTitle
          title={currentPool?.name}
          onEdit={onEdit}
          onDelete={() =>
            onDelete(inputEditType.pool, currentPool?.name, currentPool?.id)
          }
          enableDelete={isAvailable}
          buttonDeleteLabel="Delete pool"
        />
      );
    case "<sites>":
      return editMode ? (
        <EditInputName
          loading={loading}
          type={inputEditType.site}
          defaultName={currentSite?.name}
          onCancel={onCancel}
          onAccept={(type, name) => onUpdateTitle(type, name)}
          onDelete={() => onDelete(inputEditType.site)}
          disabled
          enableDelete={isAvailable}
          buttonDeleteLabel="Delete site"
        />
      ) : (
        <EditTitle
          title={currentSite?.name}
          onEdit={onEdit}
          onDelete={() => onDelete(inputEditType.site)}
          enableDelete={isAvailable}
          buttonDeleteLabel="Delete site"
        />
      );
    case "<createSite>":
      return "Add site";
    default:
      return headerType;
  }
};

const getCopyOptions = (headerType, tenantToUse, onboardingBrokerFqdn) => {
  switch (headerType) {
    case "<onboardingTitle>":
      return {
        allowed: true,
        message: "Copied to the clipboard",
        value: onboardingBrokerFqdn,
      };
    case "<tenantName>":
    case "<tenantDetail>":
      return {
        allowed: true,
        message: "Broker FQDN copied to clipboard!",
        value: tenantToUse?.brokerFqdn,
      };
    default:
      return {};
  }
};

const Header = ({ hideActions = false }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const currentMachine = useCurrentMachine();
  const currentPool = useCurrentPool();
  const currentSite = useCurrentSite();
  const [editMode, setEditMode] = useState(false);
  const { organizationId } = useOrgAndTenantId();
  const [isLoading, setIsLoading] = useState(false);

  const { data, shouldRender } = useCTA();
  const location = useLocation();
  const { tenants } = useSelector((state) => state.partners);
  const { selectedIdentity } = useSelector((state) => state.identities);
  const { loadingPoolDetails } = useSelector(
    (state) => state.adminActions?.pools,
  );
  const { tenantId: tenantIdOnboarding } = useSelector(
    (state) => state.onboarding,
  );
  const tenantsOrg = tenants[getIdentityId(selectedIdentity)] || [];

  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const displayNameOnboarding = tenantsOrg.find(
    (i) => getTenantId(i.id) === tenantIdOnboarding,
  )?.displayName;

  const isCTAEnabled = shouldRender && !!data?.headerProps?.tenant;
  const tenantToUse = isCTAEnabled ? data.headerProps.tenant : currentTenant;
  const tenantId = tenantToUse?.id || "";
  const currentPath = location.pathname.split("?")[0];

  const onboardingBrokerFqdn = useMemo(() => {
    const selectedTenant = tenantsOrg.filter(
      (i) =>
        i.id.replace("/tenants/", "").toLowerCase() ===
        tenantIdOnboarding.toLowerCase(),
    );
    return selectedTenant[0]?.brokerFqdn;
  }, [selectedIdentity, tenantIdOnboarding, tenants]);

  const handleViewTenantDetailsClick = () => {
    const cleanedTenantId = tenantId.replace("/tenants/", "");
    history.push(`/tenants/edit/${cleanedTenantId}`);
  };

  urlHeaderMap.sort((a, b) => b.url.length - a.url.length);

  const matchingUrl = urlHeaderMap.find((b) =>
    new RegExp(b.url.toLowerCase()).test(currentPath.toLowerCase()),
  );

  const isResumingOnboarding = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get("resumeOnboarding");
    return typeParam === "true";
  }, [location.search]);

  const headerType = matchingUrl?.headerType;

  const onUpdateTitlePool = async (name) => {
    const { meta } = await dispatch(
      updatePool({
        organizationId,
        tenantId: getTenantId(tenantId),
        payload: {
          id: currentPool.id,
          name: name,
        },
        poolId: currentPool.id,
      }),
    );

    if (meta?.requestStatus === "rejected") {
      addToast({
        text: "Failed to update pool",
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
    }
    if (meta.requestStatus === "fulfilled") {
      setEditMode(false);
      addToast({
        text: `${name} has been updated.`,
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  };

  const onUpdateTitleSite = async (name) => {
    const payload = {
      organizationId: getIdentityId(selectedIdentity),
      tenantId: getTenantId(selectedPartner),
      siteName: currentSite?.name,
      payload: {
        name: name,
      },
    };
    const { meta } = await dispatch(editSites(payload));
    if (meta.requestStatus === "rejected") {
      addToast({
        text: meta.error || meta.error.message || "Unable to fetch data",
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
    }
    if (meta.requestStatus === "fulfilled") {
      history.replace(`/sites/action/edit/${name}`);
      addToast({
        text: "machine updated successfully",
        type: "positive",
        timeout: 6,
        id: Date.now(),
      });
    }
  };

  const handleUpdateTitle = async (type, name) => {
    setIsLoading(true);
    if (type === inputEditType.site) {
      await onUpdateTitleSite(name);
    }
    if (type === inputEditType.pool) {
      await onUpdateTitlePool(name);
    }
    setIsLoading(false);
  };

  const handleDelete = (type, name = "", id = "") => {
    setIsLoading(true);
    if (type === inputEditType.pool) {
      dispatch(setSelectedPoolToDelete({ open: true, name, id }));
    }
    if (type === inputEditType.site) {
      dispatch(openDialogToDeleteSite());
    }
    setIsLoading(false);
  };

  const displayTitle = getDisplayTitle({
    headerType,
    displayNameOnboarding,
    tenantToUse,
    currentMachine,
    currentPool,
    currentSite,
    tenantIdOnboarding,
    isResumingOnboarding,
    editMode,
    onEdit: () => setEditMode(true),
    onCancel: () => setEditMode(false),
    onUpdateTitle: handleUpdateTitle,
    onDelete: handleDelete,
    loading: isLoading,
  });

  const subtitle = getSubtitle(headerType, displayTitle);
  const actions = getActions(headerType, handleViewTenantDetailsClick);
  const displayTag = getDisplayTag(
    headerType,
    tenantToUse,
    currentPool,
    displayTitle,
    currentSite,
  );
  const copyOptions = getCopyOptions(
    headerType,
    tenantToUse,
    onboardingBrokerFqdn,
  );

  const isGenericHeaderLoading = loadingPoolDetails;

  useEffect(() => {
    setEditMode(false);
    dispatch(setSelectedPoolToDelete({ open: false, name: "", id: "" }));
    dispatch(closeDialogToDeleteSite());
  }, [dispatch, headerType]);

  return (
    <StyledHeaderContainer className={classes.container}>
      <div className={classes.innerContainer}>
        <GenericPageHeader
          loadingTitle={
            headerType === "<onboardingTitle>" &&
            isResumingOnboarding &&
            !displayNameOnboarding
          }
          loading={isGenericHeaderLoading}
          title={displayTitle}
          subtitle={subtitle}
          tagComponent={displayTag}
          tooltipText={
            headerType === "<poolName>" &&
            mapPoolsTagLabel(formatLabelToCamelCase(currentPool?.status)) ===
              "Action required"
              ? ACTION_REQUIRED_TOOLTIP_TEXT[
                  formatLabelToCamelCase(currentPool?.status)
                ]
              : undefined
          }
          copyOptions={copyOptions}
        >
          {!hideActions && actions}
        </GenericPageHeader>
      </div>
    </StyledHeaderContainer>
  );
};

export default Header;
