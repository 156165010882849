import { Grid, useTheme } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeviceImages,
  getGalleryImages,
} from "../../admin-actions/adminActionsSlice";
import { useErrorHandling } from "../../admin-actions/pools/hooks/useErrorHandling";
import SelectInput from "../../common/components/form/SelectInput";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import { setDeviceImage } from "../onboardingSlice";
import { validateDeviceImageAdvanced } from "../onboardingUtils";

const SelectDeviceImage = React.forwardRef((props, ref) => {
  const {
    onFormValidityChange,
    initialValues,
    setFormValues,
    onChange,
    description,
    required,
  } = props;
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const theme = useTheme();

  const [displayOptions, setDisplayOptions] = useState([]);
  const {
    galleryImages,
    loadingDeviceImages,
    loadingGalleryImages,
    deviceImages,
  } = useSelector((state) => state.adminActions.deschutes);
  const { tenantId } = useSelector((state) => state.onboarding);

  const { errorMessages, handleError, resetErrors } = useErrorHandling();

  const { organizationId, tenantId: tenantIdFromPartner } = useOrgAndTenantId();

  useEffect(() => {
    dispatch(setDeviceImage(null));
  }, []);

  useEffect(() => {
    const customImages = [];
    const defaultImages = [];
    galleryImages.forEach((item) => {
      defaultImages.push({
        value: item.imageId,
        label: item.imageDisplayName,
      });
    });

    deviceImages[tenantId]?.forEach((item) => {
      customImages.push({
        value: item.imageId,
        label: item.imageDisplayName,
      });
    });

    const options = [];

    if (defaultImages.length > 0) {
      options.push({
        group: "Default Images",
        options: defaultImages,
      });
    }

    if (customImages.length > 0) {
      options.push({
        group: "Custom Images",
        options: customImages,
      });
    }

    setDisplayOptions(options);
  }, [deviceImages, galleryImages, tenantId]);

  useEffect(() => {
    const tenantIdToUse = tenantId || tenantIdFromPartner;

    if (tenantIdToUse && organizationId) {
      dispatch(
        getDeviceImages({
          useCache: true,
          selectedTenant: tenantIdToUse,
          organizationId,
        }),
      )
        .then(unwrapResult)
        .catch((error) => handleError(error, "device images"));

      dispatch(
        getGalleryImages({
          useCache: true,
          selectedTenant: tenantIdToUse,
          organizationId,
        }),
      )
        .then(unwrapResult)
        .catch((error) => handleError(error, "gallery images"));
    }
  }, [dispatch, handleError, organizationId, tenantId, tenantIdFromPartner]);

  const onSelectionSubmit = (values) => {
    props.onSubmit({ ...values });
  };

  const handleChange = (name, value, form) => {
    form.change(name, value);
    setFormValues((values) => ({ ...values, [name]: value }));
    if (onChange) {
      onChange(value);
    }
  };

  const [messagesAlreadyNotified, setMessagesAlreadyNotified] = useState([]);

  useEffect(() => {
    if (errorMessages.length > 0) {
      const newMessages = errorMessages.filter(
        (item) => !messagesAlreadyNotified.includes(item),
      );
      if (newMessages.length > 0) {
        setMessagesAlreadyNotified((prevMessages) => [
          ...prevMessages,
          ...newMessages,
        ]);
        newMessages.map((message) =>
          addToast({
            text: message,
            type: "negative",
            timeout: 6,
            id: Date.now(),
          }),
        );
      }
    }
  }, [addToast, errorMessages, messagesAlreadyNotified, resetErrors]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Form
            onSubmit={onSelectionSubmit}
            initialValues={initialValues}
            validate={(values) => {
              const valid = validateDeviceImageAdvanced({ ...values });
              if (onFormValidityChange) {
                onFormValidityChange(valid);
              }
              return valid;
            }}
            render={(formMeta) => {
              const form = formMeta.form;
              return (
                <form onSubmit={formMeta.handleSubmit} ref={ref}>
                  <Grid container spacing={1}>
                    {description && (
                      <Grid item xs={12} color={theme.palette.foreground.light}>
                        <p>{description}</p>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Field
                        name="deviceImage"
                        render={() => (
                          <SelectInput
                            onChange={(e) => {
                              handleChange("deviceImage", e, form);
                            }}
                            onClear={() => {
                              handleChange("deviceImage", null, form);
                            }}
                            required={required ?? true}
                            value={formMeta.values.deviceImage}
                            label="Device image"
                            options={displayOptions || []}
                            loading={
                              loadingDeviceImages || loadingGalleryImages
                            }
                            data-testid="onboarding-select-device-image"
                            separateLabel
                            isSearchable
                            error={
                              !loadingGalleryImages &&
                              !loadingDeviceImages &&
                              displayOptions.length === 0
                            }
                            helperText={
                              !loadingGalleryImages &&
                              !loadingDeviceImages &&
                              displayOptions.length === 0
                                ? "No Images available in your azure tenant."
                                : "Select the appropriate device image. You can either use a custom image or an Azure gallery image."
                            }
                            visibleOptions={6}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
});

SelectDeviceImage.displayName = "SelectDeviceImage";
SelectDeviceImage.propTypes = {
  onDeviceImageChange: PropTypes.func,
  currentStatus: PropTypes.bool,
  deviceImage: PropTypes.object,
  required: PropTypes.bool,
};
SelectDeviceImage.defaultProps = {
  currentStatus: false,
};

export default SelectDeviceImage;
