import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getTimeZones = createAsyncThunk(
  "getTimeZones",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { loadingTimeZonesRequestId, loadingTimeZones, timeZones } =
      getState().dashboard.tenant;

    if (!loadingTimeZones || loadingTimeZonesRequestId !== requestId) {
      return;
    }

    if (useCache && timeZones.length > 0) {
      return { data: timeZones, isCachedData: true };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/timezones/",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenantDetail = createAsyncThunk(
  "getTenant",
  async (
    { useCache = true, tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingTenantRequestId, loadingTenant, tenant } =
      getState().dashboard.tenant;

    if (!loadingTenant || loadingTenantRequestId !== requestId) {
      return;
    }

    if (useCache && tenant[tenantId]) {
      return {
        data: tenant[tenantId],
        selectedTenant: tenantId,
        isCachedData: true,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/detail`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateTenant = createAsyncThunk(
  "updateTenant",
  async (
    { tenantId, organizationId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { updatingTenantRequestId, updatingTenant } =
      getState().dashboard.tenant;

    if (!updatingTenant || updatingTenantRequestId !== requestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateTenantNotificationThresholds = createAsyncThunk(
  "updateTenantNotificationThresholds",
  async ({ tenantId, payload }, { getState, requestId, rejectWithValue }) => {
    const { updatingTenantRequestId, updatingTenant } =
      getState().dashboard.tenant;

    if (!updatingTenant || updatingTenantRequestId !== requestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/internal-support/tenants/${tenantId}/notification-thresholds`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return {
        throttleThreshold: payload.throttleThreshold,
        selectedTenant: tenantId,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAdCredentials = createAsyncThunk(
  "getAdCredentials",
  async (
    { tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAdCredentials, loadingAdCredentialsRequestId } =
      getState().dashboard.tenant;

    if (!loadingAdCredentials || loadingAdCredentialsRequestId !== requestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/adCredentials`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveADCredentials = createAsyncThunk(
  "saveADCredentials",
  async (
    { organizationId, tenantId, username, password },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingAdCredentials, loadingAdCredentialsRequestId } =
      getState().dashboard.tenant;

    if (!loadingAdCredentials || loadingAdCredentialsRequestId !== requestId) {
      return;
    }
    try {
      await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/adCredentials`,
        method: HTTP_METHODS.POST,
        data: { username, password },
      }).callAuthorizedAPI();
      return { username, password, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getWin365Licenses = createAsyncThunk(
  "getWin365Licenses",
  async (
    { organizationId, tenantId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingWin365Licenses, loadingWin365LicensesRequestId } =
      getState().dashboard.tenant;

    if (
      !loadingWin365Licenses ||
      loadingWin365LicensesRequestId !== requestId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/win365Licenses`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  tenant: {},
  loadingTenant: null,
  loadingTenantError: null,
  loadingTenantRequestId: null,
  updatingTenant: null,
  updatingTenantError: null,
  updatingTenantRequestId: null,
  loadingTimeZones: null,
  loadingTimeZonesRequestId: null,
  timeZones: [],
  loadingTimeZonesError: null,
  loadingAdCredentials: null,
  loadingAdCredentialsRequestId: null,
  loadingCredentialsError: null,
  dataAddCredentials: "",
  loadingWin365Licenses: null,
  loadingWin365LicensesRequestId: null,
  loadingWin365LicensesError: null,
  win365Licenses: [],
  availableWin365Licenses: -1,
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.loadingTenantError = null;
      state.updatingTenantError = null;
    },
  },
  extraReducers: {
    [getTimeZones.pending]: (state, action) => {
      state.loadingTimeZones = true;
      state.loadingTimeZonesRequestId = action.meta.requestId;
      state.loadingTimeZonesError = null;
    },
    [getTimeZones.fulfilled]: (state, action) => {
      state.loadingTimeZonesRequestId = null;
      state.loadingTimeZones = false;
      state.loadingTimeZonesError = "";
      if (!action.payload.isCachedData) {
        state.timeZones = action.payload.data;
      }
    },
    [getTimeZones.rejected]: (state, action) => {
      state.loadingTimeZonesRequestId = null;
      state.loadingTimeZonesError = action.payload || true;
      state.loadingTimeZones = false;
    },
    [getTenantDetail.pending]: (state, action) => {
      state.loadingTenant = true;
      state.loadingTenantRequestId = action.meta.requestId;
      state.loadingTenantError = null;
    },
    [getTenantDetail.fulfilled]: (state, action) => {
      state.loadingTenantRequestId = null;
      state.loadingTenant = false;
      state.loadingTenantError = "";
      if (action.payload?.selectedTenant) {
        _.set(
          state.tenant,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
      }
    },
    [getTenantDetail.rejected]: (state, action) => {
      state.loadingTenantRequestId = null;
      state.loadingTenantError = action.payload || true;
      state.loadingTenant = false;
    },
    [updateTenant.pending]: (state, action) => {
      state.updatingTenant = true;
      state.updatingTenantRequestId = action.meta.requestId;
      state.updatingTenantError = null;
    },
    [updateTenant.fulfilled]: (state, action) => {
      state.updatingTenantRequestId = null;
      state.updatingTenant = false;
      if (action.payload.selectedTenant) {
        _.set(
          state.tenant,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
      }
    },
    [updateTenant.rejected]: (state, action) => {
      state.updatingTenantRequestId = null;
      state.updatingTenantError = action.payload || true;
      state.updatingTenant = false;
    },
    [updateTenantNotificationThresholds.pending]: (state, action) => {
      state.updatingTenant = true;
      state.updatingTenantRequestId = action.meta.requestId;
      state.updatingTenantError = null;
    },
    [updateTenantNotificationThresholds.fulfilled]: (state, action) => {
      state.updatingTenantRequestId = null;
      state.updatingTenant = false;
      if (
        action.payload.selectedTenant &&
        state.tenant &&
        state.tenant[action.payload.selectedTenant]
      ) {
        let selectedTenantObj = state.tenant[action.payload.selectedTenant];
        if (selectedTenantObj) {
          if (!selectedTenantObj.notificationSettings) {
            selectedTenantObj.notificationSettings = {};
          }
          selectedTenantObj.notificationSettings.throttleThreshold =
            action.payload.throttleThreshold;
        }
        _.set(
          state.tenant,
          `${action.payload.selectedTenant}`,
          selectedTenantObj,
        );
      }
    },
    [updateTenantNotificationThresholds.rejected]: (state, action) => {
      state.updatingTenantRequestId = null;
      state.updatingTenantError = action.payload || true;
      state.updatingTenant = false;
    },
    [getAdCredentials.pending]: (state, action) => {
      state.loadingAdCredentials = true;
      state.loadingAdCredentialsRequestId = action.meta.requestId;
      state.loadingCredentialsError = null;
    },
    [getAdCredentials.fulfilled]: (state, action) => {
      state.dataAddCredentials = action.payload.data.username;
      state.loadingAdCredentialsRequestId = null;
      state.loadingCredentialsError = "";
      state.loadingAdCredentials = false;
    },
    [getAdCredentials.rejected]: (state, action) => {
      state.loadingAdCredentialsRequestId = null;
      state.loadingTenantError = action.payload || true;
      state.loadingAdCredentials = false;
    },
    [saveADCredentials.pending]: (state, action) => {
      state.loadingAdCredentials = true;
      state.loadingAdCredentialsRequestId = action.meta.requestId;
      state.loadingCredentialsError = null;
    },
    [saveADCredentials.fulfilled]: (state, action) => {
      state.loadingAdCredentialsRequestId = null;
      state.loadingAdCredentials = false;
      state.loadingCredentialsError = "";
      state.dataAddCredentials = action.payload.username;
    },
    [saveADCredentials.rejected]: (state, action) => {
      state.loadingAdCredentialsRequestId = null;
      state.loadingTenantError = action.payload || true;
      state.loadingAdCredentials = false;
    },
    [getWin365Licenses.pending]: (state, action) => {
      state.loadingWin365Licenses = true;
      state.loadingWin365LicensesRequestId = action.meta.requestId;
      state.loadingWin365LicensesError = null;
    },
    [getWin365Licenses.fulfilled]: (state, action) => {
      state.loadingWin365LicensesRequestId = null;
      state.loadingWin365Licenses = false;
      state.loadingWin365LicensesError = "";
      state.win365Licenses = action.payload.data.value;
      state.availableWin365Licenses = state.win365Licenses.reduce(
        (sum, license) => {
          if (
            license.prepaidUnits &&
            typeof license.prepaidUnits.enabled === "number"
          ) {
            return sum + license.prepaidUnits.enabled;
          }
          return sum;
        },
        0,
      );
    },
    [getWin365Licenses.rejected]: (state, action) => {
      state.loadingWin365LicensesRequestId = null;
      state.loadingWin365LicensesError = action.payload || true;
      state.loadingWin365Licenses = false;
    },
  },
});
export const { resetErrors } = tenantSlice.actions;
export default tenantSlice.reducer;
