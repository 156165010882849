import { useToast } from "@veneer/core";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  HYPERVISOR_TYPES,
  OPTIONS_PERSISTENT_VALUES,
  RESOURCE_POOL_TYPE,
} from "../../../../common/constants";
import { useOrgAndTenantId } from "../../../../common/hooks/useOrgAndTenantId";
import { statusMessages } from "../../../../common/utils";
import {
  checkExistingPool,
  checkNameLength,
  composeValidators,
  required,
} from "../../../../common/validators";
import PoolNameForm from "../../../../onboarding/components/poolNameForm";
import SelectAgentType from "../../../../onboarding/components/SelectAgentType";
import UserAssignmentPolicyForm from "../../../../onboarding/components/UserAssignmentPolicyForm";
import { validatePoolName } from "../../../../onboarding/onboardingUtils";
import { NAME_LIMIT_LENGTH } from "../../../adminActionsConstants";
import {
  createPool,
  setAddAgentTypeRD,
  setAddEntraGroupsRD,
  setAddPersistentRD,
  setAddPoolNameRD,
  setAddTargetUsersRD,
} from "../../poolsSlice";
import { findLastTrueWithAllPreviousTrue } from "../../poolsUtils";

//validates whether the form is enabled to move to the next step
const useCreatePoolRegisteredDevicesValidation = () => {
  const { name, persistent, agentType } = useSelector(
    (state) => state.adminActions.pools.addPoolRegisteredDevices,
  );

  const { pools } = useSelector((state) => state.adminActions.pools);

  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const poolExists = Boolean(checkExistingPool(tenantPools)(name));

  const isNameValid =
    !composeValidators(required, validatePoolName)(name) &&
    !poolExists &&
    !checkNameLength(
      NAME_LIMIT_LENGTH.pool.min,
      NAME_LIMIT_LENGTH.pool.max,
    )(name);
  const isAgentTypeValid = !!agentType;
  const isUserAssignmentPolicyValid = !!persistent;

  const validSteps = {
    isNameValid,
    isUserAssignmentPolicyValid,
    isAgentTypeValid,
  };

  validSteps.isValidToCreatePool = Object.values(validSteps).every(
    (i) => i === true,
  );

  return validSteps;
};

export const useRegisteredDevicesGetStepContent = (stepNumber) => {
  const { name, persistent, targetUsers, agentType } = useSelector(
    (state) => state.adminActions.pools.addPoolRegisteredDevices,
  );

  const deviceImageFormRef = useRef();
  const dispatch = useDispatch();

  //This function updates the redux state, it takes the data that the user is changing and which function should be executed
  const formUpdater = {
    poolNameForm: (data) => {
      dispatch(setAddPoolNameRD(data?.name));
    },
    userAssignmentPolicy: (data) => {
      dispatch(setAddPersistentRD(data?.persistent));
      if (data?.persistent === OPTIONS_PERSISTENT_VALUES.manual) {
        dispatch(setAddTargetUsersRD([]));
        dispatch(setAddEntraGroupsRD([]));
      } else {
        dispatch(setAddEntraGroupsRD(data?.entraGroups));
        dispatch(setAddTargetUsersRD(data?.targetUsers?.assignmentUser));
      }
    },
    selectAgentType: (data) => {
      dispatch(setAddAgentTypeRD(data));
    },
  };

  const handleChange = (data, form) => {
    const updateForm = formUpdater[form];
    updateForm(data);
  };

  //Creation of components to be displayed in each step.
  const steps = {
    0: <PoolNameForm onChange={handleChange} initialValues={{ name }} />,
    1: (
      <UserAssignmentPolicyForm
        initialValues={{ persistent, assignmentUser: targetUsers }}
        onSubmit={(e) => handleChange(e, "userAssignmentPolicy")}
        ref={deviceImageFormRef}
        onChange={(e) => handleChange(e, "userAssignmentPolicy")}
        setFormValues={() => {}}
        description="Select the appropriate device image. You can either use a custom image (created above) or an Azure gallery image."
      />
    ),
    2: (
      <SelectAgentType
        initialValues={{ agentType }}
        onChange={(e) => handleChange(e, "selectAgentType")}
      />
    ),
  };
  return steps[stepNumber] || "Unknown step";
};

//data for creating the step, enabling the button to move to next step(based on the validator), configuring the header, and customizing the button label
export const useRegisteredDevicesGetStepsMetaData = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const [stepErrors, setStepErrors] = useState({});

  const { name, persistent, targetUsers, agentType } = useSelector(
    (state) => state.adminActions.pools.addPoolRegisteredDevices,
  );

  const { selectedSite } = useSelector((state) => state.adminActions.pools);

  const dispatch = useDispatch();
  const validatorForm = useCreatePoolRegisteredDevicesValidation();

  const { organizationId, tenantId } = useOrgAndTenantId();

  const handleCreatePool = async () => {
    const payload = {
      name: name,
      site: selectedSite.label,
      hypervisorType: HYPERVISOR_TYPES.UNMANAGED_HYPERVISOR,
      resourcePoolType: RESOURCE_POOL_TYPE.VDI,
      poolBehavior: "Persistent",
      assignmentType: persistent,
      pcoipAgentType: agentType,
      users: targetUsers,
    };

    const resultAction = await dispatch(
      createPool({ organizationId, tenantId, payload }),
    );

    return resultAction;
  };

  const onSubmitForm = async () => {
    setStepErrors({});
    try {
      const { meta, payload, error } = await handleCreatePool();
      if (meta.requestStatus === "fulfilled") {
        setStepErrors((prevErrors) => ({ ...prevErrors, 2: false }));
        history.push("/dashboard/pools");
        return { error: false };
      }
      if (meta.requestStatus === "rejected") {
        setStepErrors((prevErrors) => ({ ...prevErrors, 2: true }));
        const messageError =
          payload?.detail ||
          error?.message ||
          statusMessages[payload?.status] ||
          "An error occurred while creating the pool.";
        addToast({
          text: messageError,
          type: "negative",
          timeout: 6,
          id: Date.now(),
        });
        return { error: true };
      }
      return { error: true };
    } catch (error) {
      addToast({
        text: `Failed to create pool: ${error}`,
        type: "negative",
        timeout: 6,
        id: Date.now(),
      });
      setStepErrors((prevErrors) => ({ ...prevErrors, 2: true }));
      return { error: true };
    }
  };

  const stepsMetadata = [
    {
      heading: "Enter pool name",
      canMoveToNextStep: validatorForm.isNameValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select user assignment policy",
      canMoveToNextStep: validatorForm.isUserAssignmentPolicyValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select agent type",
      canMoveToNextStep:
        validatorForm.isValidToCreatePool && validatorForm.isAgentTypeValid,
      stepSubmitButtonLabel: "Create pool",
      onStepSubmit: async () => await onSubmitForm(),
    },
  ];

  return {
    stepsMetadata,
    stepErrors,
  };
};

//identifies the most recent step if the user has already started filling out the form.
export const useCurrentStep = () => {
  const validatorForm = useCreatePoolRegisteredDevicesValidation();
  const currentStep = useMemo(
    () => findLastTrueWithAllPreviousTrue(Object.values(validatorForm)),
    [],
  );
  return currentStep;
};
